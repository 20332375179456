import React, { useState, useEffect, useContext, useRef } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { Container, Tooltip } from "@material-ui/core";
import { Navigation } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { useScrollPosition } from "../../hooks/useScrollPosition";
import DisciplineStage from "../molecules/DisciplineStage";
import Collection from "../organisms/Collection";
import { ContentContext } from "../../context/ContentContext";
import DisciplineHeader from "../organisms/DisciplineHeader";
import Loading from "./Loading";
import Error from "./Error";
import Dialog from "./Dialog";
import StageMainMessage from "../organisms/StageMainMessage";

const useStyles = makeStyles((theme) => ({
  stageContainer: {
    position: "relative",
    flexGrow: 1,
    padding: "0 20px 30px",
    backgroundColor: "transparent",
    marginTop: -25,
  },
  activeItems: {
    backgroundColor: "transparent",
    marginBottom: 30,
  },
  activeDiscipline: {
    display: "inline-block",
    textTransform: "uppercase",
    fontWeight: 500,
    fontSize: "1.5rem",
    textAlign: "center",
    overflowX: "hidden",
    color: "#fff",
    backgroundColor: theme.palette.primary.main,
    margin: "0 15px 2px 0",
    padding: "8px 12px",
    cursor: "pointer",
    "&:hover, &:active, &:focus": {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.secondary.main,
    },
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      textAlign: "left",
      width: "auto",
    },
  },
  backToTopBox: {
    display: "flex",
    visibility: (props) => (props.stageNavBottom >= 0 ? "hidden" : "visible"),
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    bottom: 10,
    right: 10,
    width: 30,
    height: 30,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
    boxShadow: "4px 4px 8px rgba(0,0,0,0.5)",
    zIndex: 1200,
    cursor: "pointer",
  },
}));

const Stage = (props) => {
  const history = useHistory();

  const {
    disciplineParam,
    stageParam,
    collectionId,
    concentrationId,
  } = props.match.params;

  // console.log("Stage", "concentrationId", concentrationId);

  const content = useContext(ContentContext);

  const stageDisplayName = content.getStageDisplayName(stageParam);

  let stageNavRef = useRef(null);
  const [stageNavBottom, setStageNavBottom] = useState(0);
  const styleProps = { stageNavBottom };
  const classes = useStyles(styleProps);

  const [activeCollectionId, setActiveCollectionId] = useState(null);
  const [activeConcentrationId, setActiveConcentrationId] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [stages, setStages] = useState({ stgs: [] });
  // const [disciplineSilo, setDisciplineSilo] = useState({});
  const [disciplineUrlName, setDisciplineUrlName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [isRedirect, setIsRedirect] = useState(false);

  useEffect(() => {
    //TODO: Include cancelToken in refactored logic
    const source = axios.CancelToken.source();
    // const cancelToken = { cancelToken: source.token };

    // if (disciplineParam && disciplineParam !== disciplineUrlName) {
    //   setDiscipline(disciplineParam, cancelToken);
    // }
    if (collectionId) {
      setActiveCollectionId(collectionId);
    }
    // console.log("Stage", "useEffect", "concentrationId", concentrationId);
    if (concentrationId) {
      // console.log("Stage", "aaa");
      setActiveConcentrationId(concentrationId);
    }
    const init = async () => {
      setDisciplineUrlName(disciplineParam);
      try {
        setStages(await content.getStagesAsync(disciplineParam));
        setIsLoading(false);
        setIsError(false);
      } catch (ex) {
        setIsLoading(false);
        setIsError(true);
        setErrorMsg("There was a problem loading the page.");
        // console.log("Stage", ex);
      }
    };
    init();
    return () => {
      // Cancels ongoing request if component unmounts
      source.cancel();
      setIsRedirect(false);
      setIsError(false);
      setErrorMsg("");
    };
  }, [collectionId, concentrationId, content, disciplineParam, stages]);

  // Set bottom position of Stage Nav
  useScrollPosition(
    ({ currPos }) => {
      setStageNavBottom(currPos.bottom);
    },
    [],
    stageNavRef,
    false,
    300
  );

  const handleRedirect = () => {
    setIsRedirect(true);
  };

  const handleChangeConcentration = (collId, concId) => {
    // console.log("Stage", "handleChangeConcentration", "concId", concId);
    if (collId === null) {
      setActiveCollectionId(null);
      setActiveConcentrationId(null);
    } else if (concId) {
      setActiveCollectionId(collId);
      setActiveConcentrationId(concId);
    } else if (collId && concId === null) {
      setActiveCollectionId(collId);
      setActiveConcentrationId(null);
    } else {
      setIsDialogOpen(true);
    }
  };

  const handleClickDiscipline = () => {
    history.push({
      pathname: `/discipline/${disciplineUrlName}`,
      fromStageUrlName: stageParam,
    });
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleBackToTop = () => {
    stageNavRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const emptySectionDialog = (
    <Dialog
      title="We're Sorry..."
      content="The section you have clicked is empty. Please choose another section."
      ariaContent="section is empty"
      isOpen={isDialogOpen}
      handleCloseDialog={handleCloseDialog}
      buttonText="Close"
    />
  );

  let renderProblem = null;
  if (isLoading) {
    renderProblem = <Loading />;
  }
  if (isError) {
    renderProblem = (
      <Error
        isRedirect={isRedirect}
        errorMsg={errorMsg}
        handleRedirect={handleRedirect}
      />
    );
  }

  let renderStage = null;
  if (stages) {
    const stageNames = content.getStageNames();
    const stageNamesObj = stageNames.reduce((obj, stage) => {
      obj[stage.displayName] = stage;
      return obj;
    }, {});

    const stagePosInfo = content.getStagesPositioningInfo(disciplineUrlName);

    // console.log("Stage", "stagePosInfo", stagePosInfo);

    renderStage = (
      <div>
        {emptySectionDialog}
        <DisciplineHeader disciplineUrlName={disciplineUrlName} />
        <Container className={classes.stageContainer} maxWidth="lg">
          {disciplineUrlName && (
            <div className={classes.activeItems}>
              <h3
                className={classes.activeDiscipline}
                onClick={handleClickDiscipline}
              >
                {content.getDisciplineDisplayName(disciplineUrlName)}
              </h3>
            </div>
          )}
          {stagePosInfo && <StageMainMessage info={stagePosInfo} />}
          <div id="stageNav" ref={stageNavRef}>
            {stages.stgs[stageDisplayName] && (
              <DisciplineStage
                disciplineUrlName={disciplineUrlName}
                stage={stageNamesObj[stageDisplayName]}
                activeConcentrationId={activeConcentrationId}
                isOverviewPage={false}
              />
            )}
          </div>
          <>
            {stages.stgs[stageDisplayName] &&
              stages.stgs[stageDisplayName].map((collection, i) => {
                // console.log(
                //   "Stage",
                //   "activeConcentrationId",
                //   activeConcentrationId
                // );
                return (
                  <Collection
                    key={collection.sys.id}
                    collectionIndex={i}
                    collection={collection}
                    activeConcentrationId={activeConcentrationId}
                    isCollectionOpen={collection.sys.id === activeCollectionId}
                    handleChange={handleChangeConcentration}
                  />
                );
              })}
          </>
          <div className={classes.backToTopBox} onClick={handleBackToTop}>
            <Tooltip
              title="Back to Top"
              aria-label="Back to Top"
              placement="top"
            >
              <Navigation fontSize="small" style={{ color: "#fff" }} />
            </Tooltip>
          </div>
        </Container>
      </div>
    );
  }

  return renderProblem || renderStage;
};

export default Stage;
