import React from "react";
// import { Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import Content from "../molecules/Content";

const useStyles = makeStyles((theme) => ({
  blockquote: {
    boxSizing: "border-box",
  },
}));

const Blockquote = ({ content }) => {
  const classes = useStyles();
  return (
    <blockquote className={classes.blockquote}>
      <Content content={content} />
    </blockquote>
  );
};

export default Blockquote;
