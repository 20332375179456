import React, { useState } from "react";
import { Typography, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { getAllResearchThrusts } from "../../utils/component-support";
import Content from "../molecules/Content";
import FocusEntry from "../molecules/FocusEntry";
import GalleryImages from "../molecules/GalleryImages";
import Person from "../molecules/Person";
import Resource from "../atoms/Resource";
import ResearchNavButton from "../atoms/ResearchNavButton";
import Subhead from "../atoms/Subhead";

const useStyles = makeStyles((theme) => ({
  focusWrapper: {
    backgroundColor: "#fff",
    overflow: "hidden",
    paddingTop: 20,
    marginBottom: 20,
    "& > div:last-child": {
      marginBottom: 0,
    },
    "& > div:first-child": {
      marginTop: 0,
    },
  },
  focusNameWrapper: {
    margin: "0 0 25px 0",
    [theme.breakpoints.up("sm")]: {
      margin: "0 0 25px 20px",
    },
  },
  divider: {
    borderColor: "#184b9f",
    marginTop: 0,
    marginRight: 0,
    marginBottom: 20,
    marginLeft: 0,
  },
  focusName: {
    display: "block",
    fontSize: "1.75rem",
    color: "rgba(0,0,0,0.87)",
    paddingBottom: 6,
    [theme.breakpoints.up("sm")]: {
      fontSize: "2.1rem",
    },
  },
  focusDescription: {
    paddingTop: 5,
  },
  focusContactWrapper: {
    paddingLeft: 0,
    marginBottom: 20,
    clear: "left",
    overflow: "hidden",
    [theme.breakpoints.up("sm")]: {
      paddingLeft: 20,
    },
  },
  focusElement: {
    paddingLeft: 0,
    clear: "left",
    // [theme.breakpoints.up("sm")]: {
    //   paddingLeft: 20,
    // },
  },
  researchButtonsWrapper: {
    display: "flex",
    flexDirection: "column",
    // flexWrap: "wrap",
    marginBottom: 50,
    paddingTop: 20,
  },
  researchButtonsHeading: {
    fontWeight: 700,
    width: "100%",
    marginBottom: 7,
    fontSize: "1.25rem",
  },
}));

const Focus = ({ focus }) => {
  const {
    displayName,
    description,
    contact,
    facultyLeads,
    engineeringLeads,
    capabilities,
    techniques,
    facilities,
    primaryImage,
    galleryImages,
    truckRamp,
  } = focus;

  const classes = useStyles();
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  const [activeThrustId, setActiveThrustId] = useState(null);

  const handleScroll = (id) => {
    setActiveThrustId(id);
  };

  // If no research thrusts then return empty array - not "null"
  // because the function could potentially return an empty array
  const allThrusts = facultyLeads ? getAllResearchThrusts(facultyLeads) : [];

  return (
    <div className={classes.focusWrapper}>
      {/* Focus Name, Research Nav, Focus Description */}
      {displayName && (
        <div className={classes.focusNameWrapper}>
          <Typography className={classes.focusName} variant="h4">
            {displayName}
          </Typography>
          <Divider className={classes.divider} />

          {/* Research Thrusts button navigation */}
          {allThrusts[0] && (
            <div className={classes.researchButtonsWrapper}>
              <Typography
                variant="subtitle1"
                className={classes.researchButtonsHeading}
              >
                Jump to Research Thrust
              </Typography>
              {allThrusts.map((thrust, i) => (
                <ResearchNavButton
                  key={thrust.id}
                  thrust={thrust}
                  isDesktop={isDesktop}
                  handleScroll={handleScroll}
                />
              ))}
            </div>
          )}

          {/* Primary Image */}
          {primaryImage && (
            <div className={classes.focusElement}>
              <Resource content={primaryImage} />
            </div>
          )}

          {/* Focus Description */}
          {description && (
            <div className={classes.focusDescription}>
              <Content
                key={focus.sys.id}
                content={description.content}
                isBiography
              />
            </div>
          )}
        </div>
      )}

      {/* Gallery Images */}
      {galleryImages && (
        <div className={classes.focusElement}>
          <GalleryImages galleryImages={galleryImages} />
        </div>
      )}

      {/* Contact: handled differently than other Person components */}
      {contact && (
        <>
          {contact.map((person) => (
            <div key={person.sys.id} className={classes.focusContactWrapper}>
              <Person content={person} isContact isFocusEntry />
            </div>
          ))}
        </>
      )}

      {/* Truck Ramp */}
      {truckRamp && (
        <>
          <Subhead content="Truck Ramp"></Subhead>
          <Content content={truckRamp.content} />
        </>
      )}

      {/* Molecules - styled with a left gold border with heading text */}
      {facultyLeads &&
        facultyLeads.map((facultyLead) => (
          <FocusEntry
            key={facultyLead.sys.id}
            component="Person"
            content={facultyLead}
            headingText="Faculty Lead"
            activeThrustId={activeThrustId}
          />
        ))}
      {engineeringLeads &&
        engineeringLeads.map((engineeringLead) => (
          <FocusEntry
            key={engineeringLead.sys.id}
            component="Person"
            content={engineeringLead}
            headingText="Engineering Lead"
          />
        ))}
      {facilities &&
        facilities.map((facility) => (
          <FocusEntry
            key={facility.sys.id}
            component="Facility"
            content={facility}
            headingText="Facility"
          />
        ))}
      {techniques &&
        techniques.map((technique) => (
          <FocusEntry
            key={technique.sys.id}
            component="Technique"
            content={technique}
            headingText="Research Thrust"
          />
        ))}
      {capabilities &&
        capabilities.map((capability) => (
          <FocusEntry
            key={capability.sys.id}
            component="Capability"
            content={capability}
            headingText="Capability"
          />
        ))}
    </div>
  );
};

export default Focus;
