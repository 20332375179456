import React from "react";
import Error from "../views/Error";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null,
      isRedirect: false,
      // errorMsg: ["There has been an error.", "This page doesn't exist."],
    };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ ...this.state, error, errorInfo });
  }

  handleRedirect = () => {
    this.setState(
      {
        ...this.state,
        error: null,
        errorInfo: null,
        isRedirect: true,
      },
      () => this.setState({ isRedirect: false })
    );
  };

  render() {
    if (this.state.error) {
      return (
        <Error
          errorMsg={this.props.errorMsg}
          handleRedirect={this.handleRedirect}
          isRedirect={this.state.isRedirect}
        />
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
