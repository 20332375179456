import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Content from "../molecules/Content";

const useStyles = makeStyles((theme) => ({
  dispHeroMsgWrapper: {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: "100%",
    maxWidth: 960,
    display: "flex",
    flexDirection: (props) => (props.isResponsive ? "column" : "row"),
    justifyContent: "center",
    alignItems: "center",
    padding: "0 20px",
    transform: "translate(-50%, -50%)",
  },
  dispHeroMsgTitleWrapper: {
    display: "flex",
    alignItems: "stretch",
    flex: "0 0 auto",
    marginRight: (props) => (props.isResponsive ? 0 : 15),
  },
  dispHeroMsgTitle: {
    color: (props) =>
      props.activeDispDisplayName === props.dispDisplayName
        ? "#fff"
        : "transparent",
    margin: (props) => (props.isResponsive ? "-5px 0 10px 0" : "-10px 0 0 0"),
    transition: "color 2s",
  },
  dispHeroMsgContent: {
    flex: "1 1 auto",
    width: "100%",
    color: (props) =>
      props.activeDispDisplayName === props.dispDisplayName
        ? "#fff"
        : "transparent",
    borderLeft: (props) =>
      props.activeDispDisplayName !== props.dispDisplayName ||
      props.isResponsive
        ? "2px solid transparent"
        : "2px solid #fff",
    transition: "color 2.5s, border-left 2.5s",
    paddingLeft: (props) => (props.isResponsive ? 0 : 15),
  },
}));

const HomePageHeroNavMsg = ({
  activeDispDisplayName,
  dispDisplayName,
  message,
  isResponsive,
}) => {
  const styleProps = { activeDispDisplayName, dispDisplayName, isResponsive };
  const classes = useStyles(styleProps);

  return (
    <div className={classes.dispHeroMsgWrapper}>
      <div className={classes.dispHeroMsgTitleWrapper}>
        <Typography variant="h3" className={classes.dispHeroMsgTitle}>
          {dispDisplayName}
        </Typography>
      </div>
      <div className={classes.dispHeroMsgContent}>
        {message && (
          <Content content={message.statement.content} isHomePage={true} />
        )}
      </div>
    </div>
  );
};

export default HomePageHeroNavMsg;
