import React from "react";
import { Divider } from "@material-ui/core";

import Text from "../atoms/Text";
import Paragraph from "../atoms/Paragraph";
import List from "../atoms/List";
import ListItem from "../atoms/ListItem";
import Blockquote from "../atoms/Blockquote";
import Code from "../atoms/Code";
import LinkInline from "../atoms/LinkInline";
import Subhead from "../atoms/Subhead";

// recursive component
const Content = ({
  content,
  isBiography = false,
  isListItem = false,
  isWhite = false,
  isHomePage = false,
  //TODO: THIS IS TEMP LOGIC UNTIL POSITIONING STATEMENT IS CHANGED FROM RICH TEXT TO PLAIN TEXT IN CONTENTFUL
  isPositionMessageKludge = false,
}) => {
  return (
    <>
      {content.map((node, i) => {
        let component = null;
        const { nodeType, content, data } = node;

        switch (nodeType) {
          case "paragraph":
            component = (
              <Paragraph
                key={i}
                content={content}
                isListItem={isListItem}
                isWhite={isWhite}
                isHomePage={isHomePage}
                isBiography={isBiography}
                isPositionMessageKludge={isPositionMessageKludge}
              />
            );
            break;
          case "ordered-list":
            component = (
              <List key={i} content={content} listType={"ordered-list"} />
            );
            break;
          case "unordered-list":
            component = (
              <List key={i} content={content} listType={"unordered-list"} />
            );
            break;
          case "list-item":
            component = <ListItem key={i} content={content} />;
            break;
          case "blockquote":
            component = <Blockquote key={i} content={content} />;
            break;
          case "code":
            component = <Code key={i} content={content} />;
            break;
          case "hyperlink":
            component = <LinkInline key={i} content={content} data={data} />;
            break;
          // base cases ---------------------------------------------------------
          case "hr":
            component = <Divider key={i} />;
            break;
          case "text":
            component = isPositionMessageKludge ? (
              <Subhead content={node.value} />
            ) : (
              <Text
                key={i}
                text={node.value}
                marks={node.marks}
                isWhite={isWhite}
              />
            );
            break;
          default:
            component = null;
        }

        return component;
      })}
    </>
  );
};

export default Content;
