import React, { createContext } from "react";

export const ContentContext = createContext({});

export const ContentContextProvider = ContentContext.Provider;

export const ContentContextConsumer = ContentContext.Consumer;

export const withContentContexttHOC = Component => props => (
    <ContentContextConsumer>
        {state => <Component {...props} contentService={state} />}
    </ContentContextConsumer>
);