import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import DisciplineStage from "../molecules/DisciplineStage";
import { ContentContext } from "../../context/ContentContext";

const useStyles = makeStyles((theme) => ({
  stagesFlexWrapper: {
    display: "flex",
    alignItems: "stretch",
    marginBottom: 30,
    minWidth: 0,
    minHeight: 0,
  },
  stagesWrapper: {
    marginBottom: 30,
    minWidth: 0,
    minHeight: 0,
  },
}));

const DisciplineStagesWrapper = ({ disciplineUrlName }) => {
  const classes = useStyles();
  const content = useContext(ContentContext);

  return (
    <div className={classes.stagesWrapper}>
      {/* {content.getStageNames().map((stage, i) => { */}
      {content.getDisciplineStageNames(disciplineUrlName).map((stage, i) => {
        return (
          <DisciplineStage
            key={i}
            disciplineUrlName={disciplineUrlName}
            stage={stage}
            activeConcentrationId={null}
            isOverviewPage={true}
          />
        );
      })}
    </div>
  );
};

export default DisciplineStagesWrapper;
