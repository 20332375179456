import React from "react";
import { makeStyles } from "@material-ui/core/styles";

// possible marks: bold, italic, underline, code

const useStyles = makeStyles((theme) => ({
  text: {
    display: "inline",
    textAlign: "left",
    color: (props) => (props.isWhite ? "#fff" : "inherit"),
    fontSize: "1.1rem",
    lineHeight: 1.65,
    // fontSize: (props) => (props.isWhite ? "1.2rem" : "inherit"),
    fontWeight: (props) => (props.marksArray.includes("bold") ? 700 : 300),
    fontStyle: (props) =>
      props.marksArray.includes("italic") ? "italic" : "normal",
    textDecorationLine: (props) => props.decoration || "none",
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.3rem",
      lineHeight: 1.5,
    },
  },
}));

const Text = ({ text, marks, isWhite }) => {
  const marksArray = marks.map((mark) => mark.type);
  const decoration = marksArray
    .filter((mark) => ["underline", "line-through", "overline"].includes(mark))
    .join(" ");
  const styleProps = { marksArray, decoration, isWhite };
  const classes = useStyles(styleProps);

  return (
    <span className={classes.text} variant="body1">
      {text}
    </span>
  );
};

export default Text;
