import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import {
  buildFullName,
  convertStudentsToList,
} from "../../utils/component-support";
import GalleryImages from "./GalleryImages";
import Content from "./Content";
import Facility from "./Facility";
import StudentGroup from "./StudentGroup";
import Technique from "./Technique";
import Resource from "../atoms/Resource";
import Heading from "../atoms/Heading";
import LinkBlock from "../atoms/LinkBlock";
import Subhead from "../atoms/Subhead";
import SubheadCaption from "../atoms/SubheadCaption";
import SubSectionHeading from "../atoms/SubSectionHeading";

const useStyles = makeStyles((theme) => ({
  personDetails: {
    marginBottom: 30,
    marginTop: (props) =>
      props.isContact && !props.isFocusEntry ? 30 : "inherit",
    overflow: "hidden",
    clear: "left",
  },
  contactText: {
    paddingTop: 10,
    paddingLeft: 0,
  },

  // **These styles MUST be matching for Person, Facility & Capability components
  moleculeSubsectionWrapper: {
    paddingTop: 10,
    paddingBottom: 0,
    marginBottom: 30, // set to 0 by focusEntryContainer:last-child or moleculeSubsection:last-child
    overflow: "hidden",
    clear: "left",
    "& > div:last-child": {
      paddingBottom: 0,
    },
  },
  moleculeSubsection: {
    borderLeft: "solid .5px #eba900",
    paddingLeft: 20,
    paddingBottom: 30, // set to 0 by moleculeSubsectionWrapper:last-child
    overflow: "hidden",
    "& > div:last-child": {
      marginBottom: 0,
    },
  },
}));

const Person = ({
  activeThrustId,
  content,
  isContact = false,
  isFocusEntry = false,
}) => {
  const {
    primaryImage,
    emailAddress,
    phone,
    position,
    biography,
    galleryImages,
    sunyPolyFacultyStaffPage,
    publicationsUrl,
    facilities,
    research,
    studentGroups,
  } = content;

  const styleProps = { isContact, isFocusEntry };

  const classes = useStyles(styleProps);

  const fullName = buildFullName(content);

  // Transforms studentGroups.students to a string of comma-separated names
  if (studentGroups) {
    convertStudentsToList(studentGroups);
  }

  return (
    <>
      <div className={classes.personDetails}>
        {isContact && <Subhead content={"Contact"} />}
        {primaryImage && (
          <Resource content={primaryImage} imageType={"portrait"} />
        )}
        {/* {isContact && (
          <Typography
            className={classes.contactText}
            variation="body2"
            color="primary"
          >
            Contact:
          </Typography>
        )} */}
        {fullName &&
          (isFocusEntry ? (
            <Heading content={fullName} />
          ) : (
            <Subhead content={fullName} />
          ))}
        {position && <SubheadCaption content={position} />}
        {emailAddress && <LinkBlock content={emailAddress} isEmail />}
        {phone && <SubheadCaption content={phone} />}
        {biography &&
          biography.map((bio) => (
            <Content
              key={bio.sys.id}
              content={bio.bioSegment.content}
              isBiography
            />
          ))}
        {(sunyPolyFacultyStaffPage || publicationsUrl) && (
          <>
            <SubheadCaption content="Publications and Activities:" />
            {sunyPolyFacultyStaffPage && (
              <LinkBlock content={sunyPolyFacultyStaffPage} />
            )}
            {publicationsUrl && <LinkBlock content={publicationsUrl} />}
          </>
        )}
        {galleryImages && <GalleryImages galleryImages={galleryImages} />}
      </div>

      {facilities && (
        <div className={classes.moleculeSubsectionWrapper}>
          <SubSectionHeading content="Facilities" />
          {facilities.map((facility) => (
            <div className={classes.moleculeSubsection} key={facility.sys.id}>
              <Facility content={facility} />
            </div>
          ))}
        </div>
      )}

      {research && (
        <div className={classes.moleculeSubsectionWrapper}>
          <SubSectionHeading content="Research Thrusts" />
          {research.map((technique, i) => {
            return (
              <div
                className={classes.moleculeSubsection}
                key={technique.sys.id}
              >
                <Technique
                  content={technique}
                  activeThrustId={activeThrustId}
                />
              </div>
            );
          })}
        </div>
      )}

      {studentGroups && (
        <div className={classes.moleculeSubsectionWrapper}>
          <SubSectionHeading content="Student Groups" />
          {studentGroups.map((studentGroup) => (
            <div
              className={classes.moleculeSubsection}
              key={studentGroup.sys.id}
            >
              <StudentGroup studentGroup={studentGroup} />
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default Person;
