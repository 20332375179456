import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { slugify } from "../../utils/component-support";

const useStyles = makeStyles((theme) => ({
  buttonPath1: {
    backgroundColor: (props) =>
      props.isOverviewPage
        ? "#d5d6d5"
        : props.isActive
        ? theme.palette.secondary.main
        : "#d5d6d5",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      color: "#fff",
    },
    color: (props) =>
      props.isOverviewPage
        ? "rgba(0,0,0,1)"
        : props.isActive
        ? "#fff"
        : "rgba(0,0,0,1)",
    width: "100%",
    height: "100%",
    minWidth: 0,
    minHeight: 0,
  },
  buttonPath2: {
    backgroundColor: (props) =>
      props.isOverviewPage
        ? "#d5d6d5"
        : props.isActive
        ? theme.palette.secondary.main
        : "#d5d6d5",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      color: "#fff",
    },
    color: (props) =>
      props.isOverviewPage
        ? "rgba(0,0,0,1)"
        : props.isActive
        ? "#fff"
        : "rgba(0,0,0,1)",
    width: "100%",
    height: "100%",
    minWidth: 0,
    minHeight: 0,
  },
}));

const DisciplineNavButton = ({
  disciplineUrlName,
  stageUrlName,
  collectionId,
  collectionDisplayName,
  concentrationId,
  concentrationDisplayName,
  pathPosition,
  pathCount,
  isActive,
  isOverviewPage,
}) => {
  const styleProps = { isActive, isOverviewPage };
  const classes = useStyles(styleProps);
  const history = useHistory();

  const handleClick = () => {
    history.push(
      `/discipline/${disciplineUrlName}/${stageUrlName}/${slugify(
        collectionDisplayName
      )}/${collectionId}/${slugify(
        concentrationDisplayName
      )}/${concentrationId}`
    );
  };

  return (
    <Button
      variant="contained"
      fullWidth
      //(ak) With both pathPosition and pathCount, we can create conditional logic for styling
      className={
        pathPosition === 1 && pathCount === 2
          ? classes.buttonPath1
          : classes.buttonPath2
      }
      onClick={handleClick}
    >
      {concentrationDisplayName}
    </Button>
  );
};

export default DisciplineNavButton;
