import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import ResizeObserver from "resize-observer-polyfill";
import {
  MuiThemeProvider,
  createMuiTheme,
  responsiveFontSizes,
} from "@material-ui/core";

import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

window.ResizeObserver = ResizeObserver;

let theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1020,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: "#184b9f",
    },
    secondary: {
      main: "#eba900",
    },
    info: {
      main: "#939393",
    },
    background: {
      default: "#fff",
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 0,
        "&:hover, &:active, &:focus": {
          backgroundColor: "#eba900",
        },
      },
      contained: {
        boxShadow: "none",
        "&:hover": {
          backgroundColor: "#eba900",
          boxShadow: "none",
        },
      },
      containedPrimary: {
        boxShadow: "none",
        "&:hover": {
          backgroundColor: "#eba900",
          boxShadow: "none",
        },
      },
      label: {
        textTransform: "none",
      },
      outlined: {
        color: "#184b9f",
        borderColor: "#184b9f",
        "&:hover, &:active, &:focus": {
          borderColor: "#eba900",
        },
        padding: "1px 15px",
      },
      text: {
        backgroundColor: "transparent",
        transition: "background-color .2s, color .2s",
        "&:hover, &:active, &:focus": {
          color: "#fff",
          backgroundColor: "#eba900",
        },
      },
      textPrimary: {
        backgroundColor: "transparent",
        transition: "background-color .2s, color .2s",
        "&:hover, &:active, &:focus": {
          color: "#fff",
          backgroundColor: "#eba900",
        },
      },
    },
    MuiDialogTitle: {
      root: {
        color: "#184b9f",
        fontSize: "1.5em",
      },
    },
    MuiDivider: {
      root: {
        margin: "20px 0",
      },
    },
    MuiMenu: {
      list: {
        padding: 0,
      },
      paper: {
        backgroundColor: "#939393",
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 0,
      },
    },
    MuiPaper: {
      root: {
        padding: "10px 0 10px",
        marginBottom: 30,
      },
      rounded: {
        borderRadius: 0,
      },
    },
    MuiTypography: {
      h3: {
        fontWeight: 300,
      },
    },
  },
  typography: {
    fontFamily: [
      '"Open Sans"',
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

theme = responsiveFontSizes(theme);

ReactDOM.render(
  <React.StrictMode>
    <MuiThemeProvider theme={theme}>
      <App />
    </MuiThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
