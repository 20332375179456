import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import Content from "../molecules/Content";

const useStyles = makeStyles((theme) => ({
  paragraph: {
    boxSizing: "border-box",
    // Aligns text with top of images
    paddingTop: (props) => (props.isListItem ? 0 : props.isHomePage ? 0 : 5),
    marginBottom: (props) => (props.isListItem ? 5 : props.isHomePage ? 0 : 15),
    // clear: (props) => (props.isBiography ? "both" : "none"),
  },
}));

const Paragraph = ({
  content,
  isListItem = false,
  isWhite = false,
  isHomePage = false,
  isBiography = false,
  //TODO: THIS IS TEMP LOGIC UNTIL POSITIONING STATEMENT IS CHANGED FROM RICH TEXT TO PLAIN TEXT IN CONTENTFUL
  isPositionMessageKludge = false,
}) => {
  const styleProps = { isListItem, isHomePage, isBiography };
  const classes = useStyles(styleProps);

  return (
    <Typography className={classes.paragraph} variant="body1">
      <Content
        content={content}
        isWhite={isWhite}
        isPositionMessageKludge={isPositionMessageKludge}
      />
    </Typography>
  );
};

export default Paragraph;
