import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  parallax: {
    width: "100%",
    height: (props) => props.ht,
    backgroundColor: theme.palette.primary.main,
    backgroundImage: (props) => `url(${props.img})`,
    backgroundPositionX: "center",
    backgroundPositionY: (props) => props.parallaxOffset,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    margin: 0,
  },
}));

// TODO: Add logic so the scrolling starts when element comes into view
//       rather than starting at the top of the full page
const ParallaxImage = ({ img, ht }) => {
  const [docHeight, setDocHeight] = useState(0);
  const [screenHeight, setScreenHeight] = useState(0);
  const [yOffset, setYOffset] = useState(0);
  const [parallaxOffset, setParallaxOffset] = useState(0);

  const parallaxShift = () => {
    setDocHeight(document.body.clientHeight);
    setScreenHeight(window.innerHeight);
    setYOffset(window.pageYOffset);
    setParallaxOffset(yOffset / (docHeight - screenHeight));
  };

  useEffect(() => {
    window.addEventListener("scroll", parallaxShift);
    return () => {
      window.removeEventListener("scroll", parallaxShift);
    };
  });

  const styleProps = { img, ht, parallaxOffset: `${parallaxOffset * 100}%` };
  const classes = useStyles(styleProps);

  return <div className={classes.parallax}></div>;
};

export default ParallaxImage;
