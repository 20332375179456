import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  portrait: {
    position: "relative",
    display: "block",
    float: "none",
    width: 160,
    height: 200,
    background: "#eee",
    backgroundImage: (props) => `url(${props.imageUrl})`,
    backgroundSize: "cover",
    backgroundPosition: "50% 50%",
    backgroundRepeat: "no-repeat",
    border: ".5px solid rgba(0,0,0,0.2)",
    margin: "0 20px 15px 0",
    [theme.breakpoints.up("sm")]: {
      float: "left",
    },
  },
  primaryImage: {
    display: "block",
    float: "none",
    margin: "14px 30px 0px 0",
    // paddingTop: ".7rem",
    border: ".5px solid rgba(0,0,0,0.2)",
    // width: (props) => props.dims.w,
    height: (props) => props.dims.h,
    [theme.breakpoints.up("sm")]: {
      float: "left",
    },
  },
  galleryImage: {
    margin: "0 15px 0px 0",
    // maxWidth: "100%",
    // width: (props) => props.dims.w,
    height: (props) => props.dims.h,
    border: ".5px solid rgba(0,0,0,0.2)",
  },
}));

const Image = ({ image, imageType }) => {
  //TODO: Better way to handle this
  const dims =
    image.orientation === "square"
      ? { w: 200, h: 200 }
      : image.orientation === "portrait"
      ? { w: 160, h: 200 }
      : { w: 300, h: 200 };
  const styleProps = {
    imageUrl: image.mediaUrl,
    dims,
  };
  // console.log("Image", styleProps);
  const classes = useStyles(styleProps);

  //In this case, "portrait" is not about orientation.  It's  an image that is literally the portrait of a person.
  const isPortrait = imageType === "portrait";

  const { mediaUrl, mediaAltText, ariaLabel } = image;

  return isPortrait ? (
    <div
      className={classes.portrait}
      title={mediaAltText}
      aria-label={ariaLabel}
    ></div>
  ) : (
    <>
      {/* <text>{image.orientation}</text> */}
      <img
        className={
          imageType === "primaryImage"
            ? classes.primaryImage
            : classes.galleryImage
        }
        src={mediaUrl}
        alt={mediaAltText}
        title={ariaLabel}
        aria-label={ariaLabel}
      />
    </>
  );
};

export default Image;
