import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  subSectionHeading: {
    boxSizing: "border-box",
    color: theme.palette.secondary.main,
    fontSize: "1.15rem",
    fontWeight: 700,
    marginBottom: 10,
    paddingTop: 10,
    clear: "left",
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.35rem",
    },
  },
}));

const SubSectionHeading = ({ content }) => {
  const classes = useStyles();

  return (
    <Typography className={classes.subSectionHeading} variant="h5">
      {content}
    </Typography>
  );
};

export default SubSectionHeading;
