import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Content from "../molecules/Content";

const useStyles = makeStyles((theme) => ({
  messageWrap: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // marginBottom: 30,
    // [theme.breakpoints.up("md")]: {
    //   marginBottom: 40,
    // },
    margin: 0,
  },
}));

const StageMainMessage = ({ info }) => {
  const classes = useStyles();
  return (
    info && (
      <Grid container spacing={2} className={classes.messageWrap}>
        {/* <Grid item md={2}></Grid> */}
        <Grid container item spacing={2} xs={12} md={11}>
          <Grid item xs={12}>
            <Typography variant="h4">{info.headline}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Content content={info.description} />
          </Grid>
        </Grid>
        {/* <Grid item md={2}></Grid> */}
      </Grid>
    )
  );
};

export default StageMainMessage;
