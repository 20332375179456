import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  imageCaption: {
    // fontWeight: 700,
    boxSizing: "border-box",
    width: (props) => props.width,
  },
}));

const ImageCaption = ({ text, orientation }) => {
  const width =
    orientation === "square" ? 200 : orientation === "portrait" ? 160 : 300;
  const styleProps = { width };
  const classes = useStyles(styleProps);
  return (
    <Typography className={classes.imageCaption} variant="body2">
      {text}
    </Typography>
  );
};

export default ImageCaption;
