import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { AppBar, Typography, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import logo from "../../assets/AMP-TAP-Logo-horz-ko.svg";
import bgImg from "../../assets/AMP-TAP-home-page-ftr-bg.jpg";

const useStyles = makeStyles((theme) => ({
  footer: {
    // flexGrow: 0,
    // flexShrink: 0,
    flex: "0 0 auto",
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
    alignContent: "center",
    minHeight: 160,
    alignSelf: "flex-end",
    backgroundColor: theme.palette.primary.main,
    backgroundImage: `url(${bgImg})`,
    borderTop: `10px solid ${theme.palette.secondary.main}`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    marginTop: "auto",
    marginBottom: 0,
    padding: "80px 20px",
  },
  legalWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
  logoLink: {
    marginBottom: 20,
  },
  logo: {
    height: 60,
    [theme.breakpoints.up("md")]: {
      height: 75,
    },
    transition: "height 0.5s",
  },
  miceType: {
    display: "inline-block",
    fontSize: ".9em",
    color: "#fff",
    margin: "0 0 10px",
    [theme.breakpoints.up("md")]: {
      display: "block",
      margin: "0 10px",
    },
  },
  link: {
    display: "inline-block",
    fontSize: ".9em",
    color: "#fff",
    margin: "0 0 10px",
    [theme.breakpoints.up("md")]: {
      display: "block",
      margin: "0 10px",
    },
  },
}));

// Forwards ref from MUI Link component to react-router Link
const ForwardRouterLink = React.forwardRef((props, ref) => (
  <RouterLink {...props} innerRef={ref} />
));

const Footer = () => {
  const classes = useStyles();
  const copyright = `© 2020-${new Date().getFullYear()}, Research Foundation for the State University of New York`;
  return (
    <AppBar position="relative" className={classes.footer}>
      <Link className={classes.logoLink} component={ForwardRouterLink} to="/">
        <img src={logo} alt="AMP TAP" className={classes.logo}></img>
      </Link>

      <div className={classes.legalWrapper}>
        <Typography variant="body1" className={classes.miceType}>
          {copyright}
        </Typography>

        <Link className={classes.link} component={ForwardRouterLink} to="/">
          Privacy Policy
        </Link>

        <Link className={classes.link} component={ForwardRouterLink} to="/">
          Terms & Conditions
        </Link>

        <Link className={classes.link} component={ForwardRouterLink} to="/">
          Site Map
        </Link>
      </div>
    </AppBar>
  );
};

export default Footer;
