import React from "react";
// import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import Content from "../molecules/Content";

const useStyles = makeStyles((theme) => ({
  listItem: {
    textAlign: "left",
  },
}));

const ListItem = ({ content }) => {
  const classes = useStyles();

  return (
    <li className={classes.listItem}>
      <Content content={content} isListItem />
    </li>
  );
};

export default ListItem;
