import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import DisciplineNavButton from "../atoms/DisciplineNavButton";

const useStyles = makeStyles((theme) => ({
  collectionWrapper: {
    // backgroundColor: "blue",
    display: "flex",
    flexDirection: "row",
    alignItems: "stretch",
    margin: 0,
    padding: 0,
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: "auto",
  },
  disciplineNavButtonWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "stretch",
  },
}));

const DisciplineCollection = (props) => {
  const {
    currentCollection,
    pathCount,
    disciplineUrlName,
    stage,
    activeConcentrationId,
    isOverviewPage,
  } = props;

  const classes = useStyles();

  return (
    <Grid
      container
      item
      spacing={1}
      xs={12}
      md={12 / pathCount}
      className={classes.collectionWrapper}
    >
      {currentCollection.concentrations &&
        currentCollection.concentrations.map((item, i) => {
          return (
            <Grid
              key={item.concentrationUrlName + "-" + i}
              item
              xs={12}
              md={pathCount > 2 ? 12 : 6}
              className={classes.disciplineNavButtonWrapper}
            >
              <DisciplineNavButton
                disciplineUrlName={disciplineUrlName}
                stageUrlName={stage.urlSlug}
                collectionId={currentCollection.sys.id}
                collectionDisplayName={currentCollection.displayName}
                pathCount={pathCount}
                pathPosition={item.collectionPathPos}
                concentrationId={item.sys.id}
                concentrationDisplayName={item.displayName}
                isActive={activeConcentrationId === item.sys.id}
                isOverviewPage={isOverviewPage}
              />
            </Grid>
          );
        })}
    </Grid>
  );
};

export default DisciplineCollection;
