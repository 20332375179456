import React, { useState } from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import Content from "../molecules/Content";
import Input from "../atoms/Input";

const useStyles = makeStyles((theme) => ({
  content1Wrapper: {
    position: "relative",
    display: "flex",
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center",
    padding: (props) =>
      props.isHeaderContent ? "70px 20px 100px" : "90px 20px",
  },
  content1: {
    maxWidth: 600,
  },
  content2Wrapper: {
    maxWidth: 600,
    margin: "100px auto",
  },
  title: {
    textAlign: "center",
    fontWeight: 300,
    marginBottom: 20,
  },
  content: {
    textAlign: "center",
  },
  fieldSet: {
    marginBottom: 16,
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "center",
  },
}));

const HomePageForm = ({ headline, content, submitForm }) => {
  const styleProps = {};
  const classes = useStyles(styleProps);

  const initialFormValues = {
    firstName: "",
    lastName: "",
    email: "",
  };

  const [formData, setFormData] = useState(initialFormValues);
  const [validationErrors, setValidationErrors] = useState({
    initialFormValues,
  });

  const isValid = () => {
    let temp = {};
    temp.firstName = formData.firstName ? "" : "Required field";
    temp.lastName = formData.lastName ? "" : "Required field";
    temp.email = /.+@.+\..+/.test(formData.email) ? "" : "Valid email required";
    setValidationErrors({ ...temp });

    return Object.values(temp).every((x) => x === "");
  };

  const handleChange = (ev) => {
    const { name, value } = ev.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (ev) => {
    ev.preventDefault();

    if (isValid()) {
      submitForm(formData);
      setFormData(initialFormValues);
    }
  };

  return (
    <div className={classes.content1Wrapper}>
      <div className={classes.content1}>
        <Typography variant="h3" className={classes.title}>
          {headline}
        </Typography>
        <Typography variant="h5" className={classes.content}>
          <Content content={content} />
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} className={classes.fieldSet}>
            <Grid container item spacing={2}>
              <Grid item xs={12} md={6}>
                <Input
                  name="firstName"
                  label="First Name"
                  type="text"
                  errors={validationErrors}
                  value={formData.firstName}
                  handleChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Input
                  name="lastName"
                  label="Last Name"
                  type="text"
                  errors={validationErrors}
                  value={formData.lastName}
                  handleChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid container item spacing={2}>
              <Grid item xs={12}>
                <Input
                  name="email"
                  label="Email"
                  type="email"
                  errors={validationErrors}
                  value={formData.email}
                  handleChange={handleChange}
                />
              </Grid>
            </Grid>
          </Grid>
          <div className={classes.buttonWrapper}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              type="submit"
            >
              SIGN UP
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default HomePageForm;
