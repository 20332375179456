import React from "react";
import { Grid, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  researchButton: {
    display: "flex",
    flex: 0.9,
    justifyContent: "flex-start",
    alignItems: "flex-start",
    lineHeight: 1.4,
    // minHeight: 40,
    // padding: "5px 12px",
    // margin: "0 10px 10px 0",
    backgroundColor: (props) =>
      props.isActive ? theme.palette.secondary.main : "#fff",
    "&:hover, &:active, &:focus": {
      backgroundColor: theme.palette.secondary.main,
    },
    borderColor: (props) =>
      props.isActive ? theme.palette.secondary.main : "#184b9f",
  },
}));

const ResearchNavButton = ({ thrust, isDesktop, handleScroll, i }) => {
  const classes = useStyles();

  const handleClick = () => {
    handleScroll(thrust.id);
  };

  return (
    <Grid container direction="row">
      <Button
        // variant="outlined"
        className={classes.researchButton}
        fullWidth={isDesktop ? false : true}
        onClick={handleClick}
        style={{
          textAlign: "left",
          color: "rgb(24, 75, 159)",
          fontSize: "1rem",
          fontWeight: "bold",
        }}
      >
        {thrust.displayName ? thrust.displayName : `Research Thrust ${i + 1}`}
      </Button>
    </Grid>
  );
};

export default ResearchNavButton;
