import React from "react";
import { Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  linkBlock: {
    display: "block",
    marginBottom: 10,
    textDecoration: "underline",
    fontSize: "0.875rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: "1rem",
    },
  },
}));

const LinkBlock = ({ content, isEmail = false, linkDisplay = null }) => {
  const classes = useStyles();

  const hrefUrl = isEmail ? `mailto: ${content}` : content;

  return (
    <Link className={classes.linkBlock} href={hrefUrl}>
      {linkDisplay ? linkDisplay : content}
    </Link>
  );
};

export default LinkBlock;
