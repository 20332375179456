import React, { useEffect, useRef } from "react";

import GalleryImages from "./GalleryImages";
import Content from "./Content";
import Heading from "../atoms/Heading";
import Resource from "../atoms/Resource";
import Subhead from "../atoms/Subhead";

const Technique = ({ isFocusEntry, content, activeThrustId = null }) => {
  const {
    sys,
    // entityType,
    // type,
    associatedLink,
    primaryImage,
    galleryImages,
    displayName,
    thrustDescription,
  } = content;

  const thrustRef = useRef(null);

  useEffect(() => {
    if (activeThrustId === sys.id) {
      thrustRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [activeThrustId, sys.id]);

  return (
    <div ref={thrustRef}>
      {displayName &&
        (isFocusEntry ? (
          <Heading content={displayName} />
        ) : (
          <Subhead content={displayName} />
        ))}
      {primaryImage && (
        <Resource content={primaryImage} imageType="primaryImage" />
      )}
      {thrustDescription && <Content content={thrustDescription.content} />}
      {associatedLink && (
        <Resource isFocusEntry={false} content={associatedLink} />
      )}
      {galleryImages && <GalleryImages galleryImages={galleryImages} />}
    </div>
  );
};

export default Technique;
