import { useEffect, useState } from "react";

export const useResizeObserver = (ref) => {
  const [nodeCurrent, setNodeCurrent] = useState(null);
  useEffect(() => {
    const observeTarget = ref.current;
    const resizeObserver = new ResizeObserver((entries) => {
      setNodeCurrent(entries[0]);
    });
    resizeObserver.observe(observeTarget);
    return () => {
      resizeObserver.unobserve(observeTarget);
    };
  }, [ref]);
  return nodeCurrent;
};
