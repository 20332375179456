import React, { useState, useEffect, useRef } from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import molecules from "../../utils/molecules-index";

const useStyles = makeStyles((theme) => ({
  focusEntryContainer: {
    padding: "20px 0 0 0",
    marginBottom: 40,
    width: "100%",
    position: "relative",
    borderTop: "30px solid #eba900",
    borderLeft: "none",
    minHeight: 30,
    clear: "left",
    "& > div:last-child": {
      marginBottom: 0,
    },
    [theme.breakpoints.up("sm")]: {
      padding: 0,
      width: "100%",
      position: "relative",
      borderTop: "none",
      borderLeft: "30px solid #eba900",
      minHeight: (props) => props.focusEntryHeight + 24 || 0,
      "& > div:last-child": {
        marginBottom: 0,
      },
    },
  },
  focusEntry: {
    padding: 0,
    overflow: "hidden",
    "& > div:last-child": {
      marginBottom: 0,
    },
    [theme.breakpoints.up("sm")]: {
      padding: "0 0 0 20px",
    },
  },
  focusEntryHeading: {
    textTransform: "uppercase",
    fontWeight: 500,
    color: "#fff",
    background: "none",
    position: "absolute",
    top: 0,
    left: 0,
    marginTop: -30,
    padding: "3px 0 4px 12px",
    transformOrigin: "initial",
    transform: "initial",
    [theme.breakpoints.up("sm")]: {
      top: 10,
      left: 0,
      marginTop: 0,
      padding: "3px 0 4px 8px",
      transformOrigin: "0 0",
      transform: "rotate(90deg)",
    },
  },
}));

const FocusEntry = ({
  component,
  content,
  headingText,
  activeThrustId = null,
}) => {
  // Sets a ref on the FocusEntry name so the element
  // can be set to a min height of FocusEntry name's length
  const focusEntryHeadingRef = useRef(null);

  const [
    focusEntryHeadingDimensions,
    setFocusEntryHeadingDimensions,
  ] = useState(null);

  useEffect(() => {
    if (focusEntryHeadingRef.current) {
      setFocusEntryHeadingDimensions({
        width: focusEntryHeadingRef.current.offsetWidth,
        height: focusEntryHeadingRef.current.offsetHeight,
      });
    }
  }, [focusEntryHeadingRef]);

  const styleProps = {};
  if (focusEntryHeadingDimensions)
    styleProps.focusEntryHeight = focusEntryHeadingDimensions.width;
  const classes = useStyles(styleProps);

  // Focus entry child component is dynamically rendered
  const Molecule = molecules[component];

  return (
    <div className={classes.focusEntryContainer}>
      <Typography
        className={classes.focusEntryHeading}
        variant="body1"
        ref={focusEntryHeadingRef}
      >
        {headingText}
      </Typography>
      <div className={classes.focusEntry}>
        <Molecule
          isFocusEntry
          content={content}
          activeThrustId={activeThrustId}
        />
      </div>
    </div>
  );
};

export default FocusEntry;
