import xhr from "./XhrService";
import { slugify } from "../utils/component-support";
import _ from "lodash"

//TODO: ERROR TRAPPING AND LOGGING

let homePage = [];
let disciplineSilos = [];
let disciplineNames = [];
let stageNames = [];

const fetchHomePageAsync = async () => {
    if (homePage.length === 0) {
        const response = await xhr.get("/Tap/HomePage");
        homePage = response.data.data;
    }
    await fetchListsAsync()
}

const fetchDisciplineAsync = async (disciplineUrlName) => {
    await fetchListsAsync()
    if (!(disciplineUrlName in disciplineSilos)) {
        const refName = disciplineNames.filter(d => d.urlSlug === disciplineUrlName)[0].referenceName;
        const response = await xhr.get(`/Tap/DisciplinePage/${refName}`);
        disciplineSilos[disciplineUrlName] = response.data.data;
    }
    await setCollectionsByStageAsync(disciplineUrlName);
    setPaths(disciplineUrlName);
    await setStagesAsync(disciplineUrlName);
}

const fetchListsAsync = async () => {
    // if (disciplineNames.length === 0) {
    //     const response = await xhr.get(`/Tap/Lists`);
    //     disciplineNames = _.sortBy(response.data.data.disciplines, 'order');
    // }
    // if (stageNames.length === 0) {
    //     const response = await xhr.get(`/Tap/Lists`);
    //     stageNames = _.sortBy(response.data.data.stages, 'order');
    // }
    if (disciplineNames.length === 0 || stageNames.length === 0) {
        const response = await xhr.get(`/Tap/Lists`);
        disciplineNames = _.sortBy(response.data.data.disciplines, 'order');
        stageNames = _.sortBy(response.data.data.stages, 'order');
    }
}

const setStagesAsync = async (discUrl) => {
    await fetchListsAsync()
    const stgs = [];
    stageNames.map(stage =>
        stgs[stage.displayName] = filterCollections(disciplineSilos[discUrl].discipline.collections, stage.displayName)
    );
    const disciplineDisplayName = disciplineSilos[discUrl].displayName;
    const disciplineUrlName = slugify(disciplineDisplayName);
    disciplineSilos[discUrl]["discipline"]["stages"] = { stgs, disciplineDisplayName, disciplineUrlName };
};

const setCollectionsByStageAsync = async (disciplineUrlName) => {
    await fetchListsAsync();
    const collectionsByStage = [];
    // eslint-disable-next-line
    stageNames.map(stage => {
        collectionsByStage[stage.urlSlug] = filterCollections(disciplineSilos[disciplineUrlName].discipline.collections, stage.displayName);
    });
    disciplineSilos[disciplineUrlName]["discipline"]["collectionsByStage"] = collectionsByStage;
}

const setPaths = (disciplineUrlName) => {
    const pathManager = {
        paths: [],
        count: 0,
        columnNums: []
    };
    if (disciplineSilos[disciplineUrlName]) {
        const pageInfoPaths = _.sortBy(disciplineSilos[disciplineUrlName].pageInfo.paths, "position");
        pathManager.count = pageInfoPaths.length;
        pageInfoPaths.forEach(p => {
            pathManager.columnNums.push(p.position);
            pathManager.paths.push(p.displayName);
        });
        disciplineSilos[disciplineUrlName]["discipline"]["paths"] = pathManager;
    }
}

const getSilo = (disciplineUrlName) => {
    return homePage["contentSilos"].filter(s => s.displayName === getDisciplineDisplayName(disciplineUrlName))[0];
}

const getDisciplineDisplayName = (disciplineUrlName) => {
    const search = disciplineNames.filter(d => d.urlSlug === disciplineUrlName);
    return search.length > 0 ? search[0].displayName : "";
}

const getStageDisplayName = (stageUrlName) => {
    const search = stageNames.filter(d => d.urlSlug === stageUrlName);
    return search.length > 0 ? search[0].displayName : "";
}

const filterCollections = (collections, stageDisplayName) => {
    // If no collections exist return null
    let newCollections =
        collections
            ? collections.filter((collection) => {
                return collection.stage && (collection.stage.displayName === stageDisplayName);
            })
            : null;
    // If the filtered collection is empty return null
    if (newCollections) {
        return newCollections[0] ? newCollections : null;
    } else {
        return null;
    }
};


const ContentProviderService = {
    getHomePageAsync: async () => {
        await fetchHomePageAsync();
        return homePage;
    },
    getDisciplineAsync: async (disciplineUrlName) => {
        await fetchDisciplineAsync(disciplineUrlName);
        return disciplineSilos[disciplineUrlName];
    },
    getStagesAsync: async (disciplineUrlName) => {
        if (!disciplineSilos[disciplineUrlName]) {
            await fetchDisciplineAsync(disciplineUrlName);
        }
        return disciplineSilos[disciplineUrlName] ? disciplineSilos[disciplineUrlName]["discipline"]["stages"] : [];
    },
    getCollectionsForStage: (disciplineUrlName, stageUrlName) => {
        return disciplineSilos[disciplineUrlName] ? disciplineSilos[disciplineUrlName]["discipline"]["collectionsByStage"][stageUrlName] : [];
    },
    getPaths: (disciplineUrlName) => {
        return disciplineSilos[disciplineUrlName] ? disciplineSilos[disciplineUrlName]["discipline"]["paths"] : null;
    },
    getSiloPrimaryImageUrl: (disciplineUrlName) => {
        const silo = getSilo(disciplineUrlName);
        return silo && silo["primaryImage"] ? silo["primaryImage"]["mediaUrl"] : "";
    },
    getSiloPositioningStatement: (disciplineUrlName) => {
        const silo = getSilo(disciplineUrlName);
        return silo ? silo["alternatePositioningStatement"] : "";
    },
    getDisciplineDisplayName: (disciplineUrlName) => {
        return getDisciplineDisplayName(disciplineUrlName);
    },
    getDisciplineNames: () => {
        return disciplineNames;
    },
    getStageDisplayName: (stageUrlName) => {
        return getStageDisplayName(stageUrlName);
    },
    // TODO: !!! Kludge. The proper approach is to dynamically determine this based on the stage having no data
    getDisciplineStageNames: (disciplineUrlName) => {
        // console.log("getDisciplineStageNames", disciplineUrlName);
        let finalStageNames = [];
        if (disciplineUrlName === "nanoelectronics") {
            stageNames.forEach((stage, i) => {
                const doPush = stage.referenceName !== "specializedTestMetrology";
                doPush && finalStageNames.push(stage);
            });
        }
        else {
            finalStageNames = stageNames;
        }
        return finalStageNames;
    },
    getStageNames: () => {
        return stageNames;
    },
    getStagesPositioningInfo: (disciplineUrlName) => {
        if (disciplineSilos[disciplineUrlName]) {
            const pgInfo = disciplineSilos[disciplineUrlName].pageInfo;
            const positioningInfo = { headline: pgInfo.stagesPositioningHeadline, description: pgInfo.stagesPositioningStatement.content };
            return positioningInfo;
        } else {
            return null;
        }
    },
    getDisciplineHeroImageUrl: async (disciplineUrlName) => {
        await fetchHomePageAsync();
        const silo = getSilo(disciplineUrlName);
        return silo && silo["heroImage"] ? silo["heroImage"]["mediaUrl"] : "";
    },
};

export default ContentProviderService;