import React, { useState, useEffect, useRef } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useResizeObserver } from "../../hooks/useResizeObserver";

import Concentration from "./Concentration";
import Content from "../molecules/Content";
import GalleryImages from "../molecules/GalleryImages";
import Person from "../molecules/Person";
import Resource from "../atoms/Resource";
import Subhead from "../atoms/Subhead";

const useStyles = makeStyles((theme) => ({
  collectionWrapper: {
    position: "relative",
    width: "100%",
    minHeight: 38,
    backgroundColor: "transparent",
    borderLeft: "none",
    marginTop: 0,
    // cursor: "pointer",
    [theme.breakpoints.up("sm")]: {
      minHeight: (props) => (props.isOpen ? props.wrapperBaseHeight + 50 : 38),
      borderLeft: (props) =>
        props.isOpen ? `38px solid ${theme.palette.info.main}` : "none",
    },
  },
  collectionClicker: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: 38,
    cursor: "pointer",
    [theme.breakpoints.up("sm")]: {
      left: (props) => (props.isOpen ? -38 : 0),
      width: (props) => (props.isOpen ? 38 : "100%"),
      height: (props) => (props.isOpen ? "100%" : 38),
      minHeight: (props) => (props.isOpen ? props.wrapperBaseHeight + 50 : 38),
    },
  },
  collectionName: {
    display: "inline-block",
    position: "relative",
    textTransform: "uppercase",
    fontWeight: 500,
    color: "#fff",
    width: "100%",
    backgroundColor: theme.palette.info.main,
    marginBottom: 10,
    padding: "7px 0 6px 38px",
    transformOrigin: "initial",
    transform: "initial",
    "&::before": {
      content: "''",
      position: "absolute",
      top: (props) => (props.isOpen ? 14 : 13),
      left: (props) => (props.isOpen ? 14 : 16),
      width: 0,
      height: 0,
      marginRight: 10,
      borderTop: (props) =>
        !props.isOpen
          ? "6px solid transparent"
          : props.isDesktop
          ? "6px solid transparent"
          : "10px solid #fff",
      borderBottom: (props) =>
        !props.isOpen
          ? "6px solid transparent"
          : props.isDesktop
          ? "6px solid transparent"
          : "none",
      borderLeft: (props) =>
        !props.isOpen
          ? "10px solid #fff"
          : props.isDesktop
          ? "none"
          : "6px solid transparent",
      borderRight: (props) =>
        !props.isOpen
          ? "none"
          : props.isDesktop
          ? "10px solid #fff"
          : "6px solid transparent",
    },
    [theme.breakpoints.up("sm")]: {
      position: (props) => (props.isOpen ? "absolute" : "relative"),
      top: (props) => (props.isOpen ? 10 : "auto"),
      left: (props) => (props.isOpen ? 0 : "auto"),
      padding: (props) =>
        props.isOpen ? "7px 15px 6px 24px" : "7px 15px 6px 38px",
      transformOrigin: (props) => (props.isOpen ? "0 0" : "initial"),
      transform: (props) => (props.isOpen ? "rotate(90deg)" : "rotate(0deg)"),
      "&::before": {
        top: (props) => (props.isOpen ? 12 : 13),
        left: (props) => (props.isOpen ? 2 : 16),
      },
      width: (props) => (props.isOpen ? "auto" : "100%"),
    },
  },
  collectionIntro: {
    // Need to leave this syntax or breakpoint padding (below) will override
    padding: (props) => (props.isFirstCollection ? "10px 0 0 0" : "10px 0 0 0"),
    marginBottom: 30,
    overflow: "hidden",
    [theme.breakpoints.up("sm")]: {
      padding: (props) =>
        props.isFirstCollection ? "0 0 0 20px" : "20px 0 0 20px",
    },
  },
  contactText: {
    paddingTop: 10,
  },
}));

const Collection = ({
  collectionIndex,
  collection,
  activeConcentrationId,
  isCollectionOpen,
  handleChange,
}) => {
  const {
    displayName,
    positioningStatement,
    description,
    contact,
    concentrations,
    primaryImage,
    galleryImages,
    truckRamp,
  } = collection;

  // console.log("Collection", "activeConcentrationId", activeConcentrationId);

  // Sets a ref on the Collection heading so the vertical header bar
  // can be set to a min height of Collection heading width
  const headingRef = useRef(null);
  const nodeCurrent = useResizeObserver(headingRef);

  const isDesktop = useMediaQuery("(min-width:600px)");

  // Set initial state
  const [isOpen, setIsOpen] = useState(false);
  const [headingWidth, setHeadingWidth] = useState(null);

  // Needs to run on every rerender so the correct collection wrapper height is set
  useEffect(() => {
    setIsOpen(isCollectionOpen);
  }, [isCollectionOpen]);

  useEffect(() => {
    if (nodeCurrent) {
      setHeadingWidth(nodeCurrent.contentRect.width);
    }
  }, [nodeCurrent]);

  const styleProps = { isDesktop };
  if (headingWidth) {
    styleProps.wrapperBaseHeight = headingWidth;
  }
  styleProps.isOpen = isOpen;

  if (collectionIndex === 0) {
    styleProps.isFirstCollection = true;
  }

  const classes = useStyles(styleProps);

  let isIntroNotEmpty = true;
  if (
    !positioningStatement &&
    !primaryImage &&
    !description &&
    !galleryImages &&
    !contact
  ) {
    isIntroNotEmpty = false;
  }

  const handleClick = () => {
    // console.log(
    //   "Collection",
    //   "handleClick",
    //   isCollectionOpen,
    //   collection.concentrations[0].sys.id
    // );
    if (isCollectionOpen) {
      // handleChange(null, collection.concentrations[0].sys.id);
      handleChange(null, null);
    } else if (collection.concentrations) {
      // handleChange(collection.sys.id, collection.concentrations[0].sys.id);
      handleChange(collection.sys.id, null);
    } else {
      handleChange(collection.sys.id, null);
    }
  };

  return (
    <div className={classes.collectionWrapper}>
      {displayName && (
        <Typography
          className={classes.collectionName}
          variant="body1"
          ref={headingRef}
        >
          {displayName}
        </Typography>
      )}
      <div className={classes.collectionClicker} onClick={handleClick}></div>
      {isOpen && (
        <>
          {isIntroNotEmpty && (
            <div className={classes.collectionIntro}>
              {positioningStatement && (
                <Content
                  content={positioningStatement.content}
                  isPositionMessageKludge
                />
              )}
              {primaryImage && (
                <Resource content={primaryImage} imageType={"primaryImage"} />
              )}
              {description && <Content content={description.content} />}
              {galleryImages && <GalleryImages galleryImages={galleryImages} />}
              <>
                {contact &&
                  contact.map((person) => (
                    <Person key={person.sys.id} content={person} isContact />
                  ))}
              </>
              {truckRamp && (
                <>
                  <Subhead content="Truck Ramp"></Subhead>
                  <Content content={truckRamp.content} isFocusEntry />
                </>
              )}
            </div>
          )}
          <>
            {concentrations &&
              concentrations.map((concentration) => (
                <Concentration
                  key={concentration.sys.id}
                  collectionId={collection.sys.id}
                  concentration={concentration}
                  isConcentrationOpen={
                    activeConcentrationId === concentration.sys.id
                  }
                  handleChange={handleChange}
                />
              ))}
          </>
        </>
      )}
    </div>
  );
};

export default Collection;
