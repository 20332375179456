import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  primaryImage: {
    display: "block",
    float: "none",
    margin: "0 20px 15px 0",
    paddingTop: ".7rem",
    minWidth: 100,
    minHeight: 100,
    maxWidth: "100%",
    [theme.breakpoints.up("sm")]: {
      float: "left",
    },
  },
  portrait: {
    display: "block",
    float: "none",
    height: 200,
    maxWidth: "100%",
    objectFit: "cover",
    margin: "0 20px 15px 0",
    [theme.breakpoints.up("sm")]: {
      float: "left",
    },
  },
  galleryImage: {
    margin: "0 15px 15px 0",
    maxWidth: "100%",
  },
}));

const Image = ({ image, imageType }) => {
  const classes = useStyles();

  let imageClass;

  switch (imageType) {
    case "portrait":
      imageClass = classes.portrait;
      break;
    case "primaryImage":
      imageClass = classes.primaryImage;
      break;
    case "galleryImage":
      imageClass = classes.galleryImage;
      break;
    default:
      imageClass = classes.primaryImage;
      break;
  }

  const { mediaUrl, mediaAltText, ariaLabel } = image;

  return (
    <a href="#">
      <img
        className={imageClass}
        src={mediaUrl}
        alt={mediaAltText}
        title={ariaLabel}
        aria-label={ariaLabel}
      />
    </a>
  );
};

export default Image;
