import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  menuItem: {
    color: "#fff",
    backgroundColor: theme.palette.info.main,
    "&:hover, &:active, &:focus": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  menuItemActive: {
    color: "#fff",
    backgroundColor: theme.palette.primary.main,
    "&:hover, &:active, &:focus": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  menuItemText: {
    fontSize: "0.875rem",
  },
}));

// TODO: Do we need to memoize this for performance? -- not sure
// Uses forwardRef to pass the ref from MUI's Menu component to MenuItem
const HeaderMenuItem = React.forwardRef((props, ref) => {
  const { handleMenuClose, menuItemDisplayName, menuItemUrl } = props;
  const classes = useStyles();

  return (
    <MenuItem
      ref={ref}
      component={RouterLink}
      to={menuItemUrl}
      onClick={handleMenuClose}
      classes={{ root: classes.menuItemText }}
      className={classes.menuItem}
    >
      {menuItemDisplayName.toUpperCase()}
    </MenuItem>
  );
});

export default HeaderMenuItem;
