import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  imgWrapper: {
    display: "block",
    float: "left",
    width: "25%",
    height: "auto",
    padding: 0,
    margin: (props) =>
      props.cardPosition % 2 !== 0
        ? 0
        : props.isResponsive
        ? "-15px 0 0"
        : "-25px 0 0",
    cursor: "pointer",
    transition: "height 0.5s, margin 0.5s",
  },
  imgAndBorderWrapper: {
    position: "relative",
    width: "100%",
    height: "auto",
  },
  hoverBorder: {
    position: "absolute",
    top: 0,
    left: 0,
    borderWidth: (props) =>
      props.isResponsiveXs ? 3 : props.isResponsive ? 5 : 8,
    borderStyle: "solid",
    borderColor: (props) =>
      props.activeDispUrlName === props.dispUrlName ? "#fcaf25" : "transparent",
    transition: "border-color 1.5s",
    backgroundColor: "transparent",
    width: "100%",
    height: "100%",
  },
  dispImg: {
    display: "block",
    width: "100%",
    height: (props) => (props.isResponsiveXs ? 137 : "auto"),
    objectFit: (props) => (props.isResponsiveXs ? "cover" : "fill"),
  },
}));

const HomePageCard = ({
  dispUrlName,
  activeDispUrlName,
  dispImg,
  cardPosition,
  isResponsive,
  isResponsiveXs,
  handleClick,
  handleMouseEnter,
  handleMouseLeave,
}) => {
  const styleProps = {
    dispImg,
    cardPosition,
    dispUrlName,
    activeDispUrlName,
    isResponsive,
    isResponsiveXs,
  };
  const classes = useStyles(styleProps);

  return (
    <div
      className={classes.imgWrapper}
      onClick={() => handleClick(dispUrlName)}
      onMouseEnter={() => handleMouseEnter(dispUrlName)}
      onMouseLeave={handleMouseLeave}
    >
      <div className={classes.imgAndBorderWrapper}>
        <div className={classes.hoverBorder}></div>
        {dispImg && (
          <img
            src={dispImg.imageUrl}
            alt={dispUrlName}
            className={classes.dispImg}
          />
        )}
      </div>
    </div>
  );
};

export default HomePageCard;
