import React from "react";
import { Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  linkInline: {
    textDecoration: "underline",
  },
}));

const LinkInline = ({ content, data }) => {
  const classes = useStyles();
  return (
    <Link className={classes.linkInline} href={data.uri}>
      {content[0].value}
    </Link>
  );
};

export default LinkInline;
