import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import Content from "../molecules/Content";

const useStyles = makeStyles((theme) => ({
  content1Wrapper: {
    position: "relative",
    display: "flex",
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center",
    padding: (props) =>
      props.isHeaderContent ? "70px 20px 100px" : "90px 20px",
  },
  content1: {
    maxWidth: 600,
  },
  content2Wrapper: {
    maxWidth: 600,
    margin: "100px auto",
  },
  title1: {
    color: (props) => (props.isHeaderContent ? "#fff" : "inherit"),
    textAlign: "center",
    fontWeight: 300,
    marginBottom: 20,
  },
  title2: {
    textAlign: "center",
    fontWeight: 300,
  },
  content: {
    textAlign: "center",
  },
}));

const HomePageContent = ({ headline, content, isHeaderContent }) => {
  const styleProps = { isHeaderContent };
  const classes = useStyles(styleProps);

  return (
    <div className={classes.content1Wrapper}>
      <div className={classes.content1}>
        <Typography variant="h3" className={classes.title1}>
          {headline}
        </Typography>
        <Typography variant="h5" className={classes.content}>
          <Content content={content} isWhite={isHeaderContent} />
        </Typography>
      </div>
    </div>
  );
};

export default HomePageContent;
