import React from "react";
import Resource from "../atoms/Resource";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  galleryWrapper: {
    paddingTop: 15,
    minWidth: "0%",
    maxWidth: "100%",
    overflow: "hidden",
  },
  imageWrapper: {
    display: "block",
    float: "left",
    minWidth: "0%",
    maxWidth: "100%",
  },
});

const GalleryImages = ({ galleryImages }) => {
  const classes = useStyles();
  // const colWidths = Math.floor(12 / galleryImages.length);

  return (
    <>
      <Grid container className={classes.galleryWrapper}>
        {galleryImages &&
          galleryImages.map((image, i) => {
            return (
              <Grid
                // item
                // xs={colWidths}
                key={`${image.sys.id}-${Math.random() * 1000}`}
                className={classes.imageWrapper}
              >
                <Resource
                  key={`${image.sys.id}-${Math.random() * 1000}`}
                  content={image}
                  imageType={"galleryImage"}
                />
              </Grid>
            );
          })}
      </Grid>
    </>
  );
};

export default GalleryImages;
