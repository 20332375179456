import React from "react";
import { useHistory } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  stageCard: {
    display: "flex",
    justifyContent: "center",
    alignItems: "stretch",
    flex: 1,
    height: "100%",
    minWidth: 0,
    minHeight: 0,
    backgroundColor: "#184b9f",
    padding: "8px 20px",
    "&:hover": {
      cursor: (props) => (props.isOverviewPage ? "pointer" : "auto"),
    },
  },
  stageName: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    minWidth: 0,
    minHeight: 0,
    fontSize: "2rem",
    color: "rgba(255,255,255,1)",
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      fontSize: (props) =>
        !props.isTestMetrol || props.isLargeDesktop ? "2rem" : "1.7rem",
    },
  },
}));

const StageCard = ({
  stageDisplayName,
  disciplineUrlName,
  stageUrlName,
  isOverviewPage,
}) => {
  const isLargeDesktop = useMediaQuery("(min-width:1280px)");
  const styleProps = { isOverviewPage, isLargeDesktop };
  styleProps.isTestMetrol = [
    "Test/Metrology",
    "Specialized Test/Metrology",
  ].includes(stageDisplayName);
  const classes = useStyles(styleProps);

  const history = useHistory();

  const handleClick = () => {
    history.push(`/discipline/${disciplineUrlName}/${stageUrlName}`);
  };

  return (
    <div
      className={classes.stageCard}
      onClick={isOverviewPage ? handleClick : () => {}}
    >
      <Typography variant="h4" className={classes.stageName}>
        {stageDisplayName}
      </Typography>
    </div>
  );
};

export default StageCard;
