import React, { useEffect, useState, useContext } from "react";
// import { useHistory } from "react-router-dom";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { Container } from "@material-ui/core";
import DisciplineStagesWrapper from "../organisms/DisciplineStagesWrapper";
import DisciplineMainMessage from "../organisms/DisciplineMainMessage";
// import Content from "../molecules/Content";
import DisciplineHeader from "../organisms/DisciplineHeader";
import { ContentContext } from "../../context/ContentContext";
import Loading from "./Loading";
import Error from "./Error";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    padding: "80px 20px 30px",
    backgroundColor: "#fff",
  },
  activeItems: {
    position: "absolute",
    top: -25,
    backgroundColor: "transparent",
  },
  activeDiscipline: {
    display: "inline-block",
    textTransform: "uppercase",
    fontWeight: 500,
    fontSize: "1.5rem",
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.main,
    padding: "8px 12px",
    margin: "0 15px 0 0",
  },
}));

const Discipline = (props) => {
  const classes = useStyles();
  // const history = useHistory();

  const disciplineParam = props.match.params.disciplineParam;
  // const fromStageUrlName = history.location.fromStageUrlName;

  const [disciplineSilo, setDisciplineSilo] = useState({
    discipline: { positioningStatement: { content: [] } },
    pageInfo: {},
  });
  const [disciplineUrlName, setDisciplineUrlName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [isRedirect, setIsRedirect] = useState(false);
  const [stages, setStages] = useState();

  const content = useContext(ContentContext);

  useEffect(() => {
    //TODO: Implement cancelToken in refactored code
    const source = axios.CancelToken.source();
    // const cancelToken = { cancelToken: source.token };
    // setDiscipline(disciplineParam, cancelToken);
    const init = async () => {
      try {
        setDisciplineUrlName(disciplineParam);
        setDisciplineSilo(await content.getDisciplineAsync(disciplineParam));
        setStages(await content.getStagesAsync(disciplineParam));
        setIsLoading(false);
        setIsError(false);
      } catch (ex) {
        setIsLoading(false);
        setIsError(true);
        setErrorMsg("There was a problem loading the page.");
        console.log("Discipline", ex);
      }
    };
    init();
    return () => {
      // Cancels ongoing request if component unmounts
      source.cancel();
      setIsRedirect(false);
      setIsError(false);
      setErrorMsg("");
    };
  }, [content, disciplineParam]);

  const handleRedirect = () => {
    setIsRedirect(true);
  };

  // TODO: These should be a method in the content service that returns both values
  const headline = disciplineSilo.discipline.positioningHeadline;
  const statement = disciplineSilo.discipline.positioningStatement.content;

  let renderProblem = null;
  if (isLoading) {
    renderProblem = <Loading />;
  }
  if (isError) {
    renderProblem = (
      <Error
        isRedirect={isRedirect}
        errorMsg={errorMsg}
        handleRedirect={handleRedirect}
      />
    );
  }

  let renderDiscipline = null;

  if (stages) {
    renderDiscipline = (
      <>
        <DisciplineHeader disciplineUrlName={disciplineUrlName} />
        <Container maxWidth="lg" className={classes.container}>
          {disciplineUrlName && (
            <div className={classes.activeItems}>
              <h3 className={classes.activeDiscipline}>
                {content.getDisciplineDisplayName(disciplineUrlName)}
              </h3>
            </div>
          )}
          <DisciplineMainMessage headline={headline} content={statement} />
          <DisciplineStagesWrapper disciplineUrlName={disciplineUrlName} />
        </Container>
      </>
    );
  }

  return renderProblem || renderDiscipline;
};

export default Discipline;
