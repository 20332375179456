import React, { useState, useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Menu, MenuItem, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import HeaderMenuItem from "./HeaderMenuItem";
import { ContentContext } from "../../context/ContentContext";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  button: {
    color: (props) => (props.isHomePage ? "#fff" : theme.palette.primary.main),
    marginLeft: 10,
    backgroundColor: (props) =>
      props.isMenuOpen ? theme.palette.secondary.main : "transparent",
    "&:focus": {
      backgroundColor: (props) =>
        props.isMenuOpen ? theme.palette.secondary.main : "transparent",
      color: (props) =>
        props.isHomePage
          ? "#fff"
          : props.isMenuOpen
          ? "#fff"
          : theme.palette.primary.main,
    },
  },
  buttonActive: {
    marginLeft: 10,
    color: "#fff",
    backgroundColor: theme.palette.secondary.main,
  },
  buttonLogin: {
    minWidth: 32,
    color: "#fff",
    backgroundColor: theme.palette.info.main,
    marginLeft: 18,
  },
  menuItem: {
    color: "#fff",
    backgroundColor: theme.palette.info.main,
    "&:hover, &:active": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  menuItemText: {
    fontSize: "0.875rem",
  },
}));

const HeaderNavButton = ({
  activeDisciplineUrlName,
  btnUrlName,
  btnDisplayName,
  isHomePage,
}) => {
  const content = useContext(ContentContext);

  // prettier-ignore
  let isDisciplineBtn = false, btnUrl, color, variant;
  if (content.getDisciplineDisplayName(btnUrlName) !== "") {
    isDisciplineBtn = true;
    btnUrl = `/discipline/${btnUrlName}`;
    color = "primary";
    variant = "text";
  } else if (btnUrlName === "about") {
    btnUrl = "/";
    color = "primary";
    variant = "text";
  } else {
    btnUrl = "/";
    color = "default";
    variant = "contained";
  }

  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const styleProps = { isHomePage, isMenuOpen };
  const classes = useStyles(styleProps);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const menuId = `${btnUrlName}-menu`;
  const renderDisciplineBtn = (
    <div className={classes.grow}>
      <Button
        color={color}
        variant={variant}
        onClick={handleMenuOpen}
        className={
          btnUrlName === "login"
            ? classes.buttonLogin
            : activeDisciplineUrlName === btnUrlName
            ? classes.buttonActive
            : classes.button
        }
      >
        {btnDisplayName.toUpperCase()}
      </Button>
      <Menu
        anchorEl={anchorEl}
        id={menuId}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        getContentAnchorEl={null}
        keepMounted
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem
          component={RouterLink}
          to={btnUrl}
          className={classes.menuItem}
          classes={{ root: classes.menuItemText }}
        >
          {[
            "Nanoelectronics",
            "Microsystems",
            "Nanobioscience",
            "Clean Energy",
          ].includes(btnDisplayName)
            ? "OVERVIEW"
            : btnDisplayName.toUpperCase()}
        </MenuItem>
        {/* {content.getStageNames().map((entry, i) => { */}
        {content.getDisciplineStageNames(btnUrlName).map((entry, i) => {
          const menuItemUrl = btnUrl + "/" + entry.urlSlug;
          return (
            <HeaderMenuItem
              key={`${entry[0]}-${i}`}
              activeDisciplineUrlName={btnUrlName}
              handleMenuClose={handleMenuClose}
              menuItemUrlName={entry.urlSlug}
              menuItemDisplayName={entry.displayName}
              menuItemUrl={menuItemUrl}
            />
          );
        })}
      </Menu>
    </div>
  );

  const renderStandardBtn = (
    <div className={classes.grow}>
      <Button
        // TODO reset when about/login are functional - and remove onClick
        // component={RouterLink}
        // to={btnUrl}
        onClick={() => {}}
        color={color}
        variant={variant}
        className={
          btnUrlName === "login"
            ? classes.buttonLogin
            : activeDisciplineUrlName === btnUrlName
            ? classes.buttonActive
            : classes.button
        }
      >
        {btnDisplayName.toUpperCase()}
      </Button>
    </div>
  );

  return isDisciplineBtn ? renderDisciplineBtn : renderStandardBtn;
};

export default HeaderNavButton;
