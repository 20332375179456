import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";

import ErrorBoundary from "./components/organisms/ErrorBoundary";
import HomePage from "./components/views/HomePage";
import Discipline from "./components/views/Discipline";
import Stage from "./components/views/Stage";
import Footer from "./components/views/Footer";
import ContentProviderService from "./services/ContentProviderService";
import { ContentContext } from "./context/ContentContext";

const useStyles = makeStyles((theme) => ({
  // siteWrapper & siteContent needed for sticky footer in ie11
  siteWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  site: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    minHeight: "100vh",
  },
  siteContent: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: "auto",
  },
}));

const App = () => {
  const classes = useStyles();

  return (
    <>
      <CssBaseline />
      <Router>
        <div className={classes.siteWrapper}>
          <div className={classes.site}>
            <div className={classes.siteContent}>
              <Switch>
                <ErrorBoundary errorMsg="An error has occurred while loading your page.">
                  <ContentContext.Provider value={ContentProviderService}>
                    <Route exact path="/" component={HomePage} />
                    <Route path="/discipline">
                      <Route
                        exact
                        path="/discipline/:disciplineParam"
                        component={Discipline}
                      />
                      <Route
                        exact
                        path="/discipline/:disciplineParam/:stageParam"
                        component={Stage}
                      />
                      <Route
                        path="/discipline/:disciplineParam/:stageParam/:collectionParam/:collectionId/:concentrationParam/:concentrationId"
                        component={Stage}
                      />
                    </Route>
                  </ContentContext.Provider>
                </ErrorBoundary>
              </Switch>
            </div>
            <Route path="/" component={Footer} />
          </div>
        </div>
      </Router>
    </>
  );
};

export default App;
