import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Capability from "../molecules/Capability";
import GalleryImages from "../molecules/GalleryImages";
import Content from "../molecules/Content";
import Heading from "../atoms/Heading";
import Resource from "../atoms/Resource";
import Subhead from "../atoms/Subhead";
import SubSectionHeading from "../atoms/SubSectionHeading";

const useStyles = makeStyles((theme) => ({
  // **These styles MUST be matching for Person, Facility & Capability components
  moleculeSubsectionWrapper: {
    paddingTop: 10,
    paddingBottom: 0,
    marginBottom: 30, // set to 0 by focusEntryContainer:last-child or moleculeSubsection:last-child
    overflow: "hidden",
    clear: "left",
    "& > div:last-child": {
      paddingBottom: 0,
      marginBottom: 0,
    },
  },
  moleculeSubsection: {
    borderLeft: "solid .5px #eba900",
    paddingLeft: 20,
    paddingBottom: 30, // set to 0 by moleculeSubsectionWrapper:last-child
    overflow: "hidden",
    "& > div:last-child": {
      marginBottom: 0,
    },
  },
}));

const Facility = ({ content, isFocusEntry = false }) => {
  const {
    displayName,
    // facilityType,
    description,
    capabilities,
    primaryImage,
    galleryImages,
    truckRamp,
  } = content;

  const classes = useStyles();

  // console.log("Facility", isFocusEntry, displayName);

  return (
    <>
      {displayName &&
        (isFocusEntry ? (
          <Heading content={displayName} />
        ) : (
          <Subhead content={displayName} />
        ))}
      {description && <Content content={description.content} />}
      {primaryImage && (
        <Resource content={primaryImage} imageType="primaryImage" />
      )}
      {galleryImages && <GalleryImages galleryImages={galleryImages} />}
      {truckRamp && (
        <>
          <Subhead content="Truck Ramp"></Subhead>
          <Content content={truckRamp.content} />
        </>
      )}
      {capabilities && (
        <div className={classes.moleculeSubsectionWrapper}>
          <SubSectionHeading content="Capabilities" />
          {capabilities.map((capability) => (
            <div key={capability.sys.id} className={classes.moleculeSubsection}>
              <Capability content={capability} />
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default Facility;
