import React, { useState, useEffect, useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import { MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { ContentContext } from "../../context/ContentContext";

const useStyles = makeStyles((theme) => ({
  menuItem: {
    color: theme.palette.primary.main,
    backgroundColor: "#fff",
    "&:hover, &:active, &:focus": {
      color: "#fff",
      backgroundColor: theme.palette.secondary.main,
    },
  },
  menuItemActive: {
    color: "#fff",
    backgroundColor: theme.palette.secondary.main,
    "&:hover, &:active, &:focus": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  subMenuItem: {
    color: "#fff",
    backgroundColor: theme.palette.info.main,
    "&:hover, &:active, &:focus": {
      backgroundColor: theme.palette.primary.main,
    },
    paddingLeft: 30,
  },
}));

// TODO: Do we need to memoize this for performance? -- not sure
// Uses forwardRef to pass the ref from MUI's Menu component to MenuItem
const HeaderMenuItemResponsive = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const {
    activeDisciplineUrlName,
    activeRespvMenuItem,
    handleResponsiveMenuClose,
    handleResponsiveSubMenuOpen,
    menuItemUrlName,
    menuItemDisplayName,
  } = props;

  const [isMenuOpen, setIsMenuOpen] = useState(null);

  const content = useContext(ContentContext);

  const handleMenuOpen = () => {
    setIsMenuOpen(true);
    handleResponsiveSubMenuOpen(menuItemUrlName);
  };

  useEffect(() => {
    if (activeRespvMenuItem) {
      setIsMenuOpen(activeRespvMenuItem === menuItemUrlName);
    }
  }, [activeRespvMenuItem, menuItemUrlName]);

  let menuItemUrl;
  if (!["about", "login"].includes(menuItemUrlName)) {
    menuItemUrl = `/discipline/${menuItemUrlName}`;
  } else {
    // TODO: Change this when about/login are functional
    menuItemUrl = `/discipline/${activeDisciplineUrlName}`;
    // menuItemUrl = `/${menuItemUrlName}`;
  }

  let renderStandardMenuItem = (
    <MenuItem
      ref={ref}
      component={RouterLink}
      onClick={handleResponsiveMenuClose}
      to={menuItemUrl}
      className={
        activeRespvMenuItem === menuItemUrlName
          ? classes.menuItemActive
          : classes.menuItem
      }
    >
      {menuItemDisplayName.toUpperCase()}
    </MenuItem>
  );

  const renderSubMenu = (
    <>
      <MenuItem
        ref={ref}
        key={`${menuItemUrlName}-overview`}
        component={RouterLink}
        to={menuItemUrl}
        onClick={handleResponsiveMenuClose}
        className={classes.subMenuItem}
      >
        OVERVIEW
      </MenuItem>
      {/* {content.getStageNames().map((entry, i) => { */}
      {content.getDisciplineStageNames(menuItemUrlName).map((entry, i) => {
        const subMenuItemUrl = menuItemUrl + "/" + entry.urlSlug;
        return (
          <MenuItem
            ref={ref}
            key={`${entry.urlSlug}-${i}`}
            component={RouterLink}
            to={subMenuItemUrl}
            onClick={handleResponsiveMenuClose}
            className={classes.subMenuItem}
          >
            {entry.displayName.toUpperCase()}
          </MenuItem>
        );
      })}
    </>
  );

  if (!["about", "login"].includes(menuItemUrlName)) {
    return isMenuOpen ? (
      <>
        {renderStandardMenuItem}
        {renderSubMenu}
      </>
    ) : (
      <MenuItem
        ref={ref}
        onClick={handleMenuOpen}
        //   close={handleMenuClose}
        className={
          activeRespvMenuItem === menuItemUrlName
            ? classes.menuItemActive
            : classes.menuItem
        }
      >
        {menuItemDisplayName.toUpperCase()}
      </MenuItem>
    );
  } else {
    return renderStandardMenuItem;
  }
});

export default HeaderMenuItemResponsive;
