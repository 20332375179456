import React from "react";
import { Redirect, useHistory } from "react-router-dom";
import { Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  errorWrapper: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  error: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    maxWidth: 600,
  },
  heading: {
    textAlign: "center",
    marginBottom: 20,
  },
  body: {
    textAlign: "center",
    marginBottom: 50,
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const Error = ({ isRedirect, errorMsg, handleRedirect }) => {
  const classes = useStyles();
  const history = useHistory();
  const isHomePage = history.location.pathname === "/";

  return isRedirect ? (
    <Redirect to="/" />
  ) : (
    <div className={classes.errorWrapper}>
      <div className={classes.error}>
        <Typography variant="h3" className={classes.heading}>
          So sorry...
        </Typography>
        <Typography variant="body1" className={classes.body}>
          {errorMsg}
        </Typography>
        {isHomePage ? (
          <Typography variant="body1" className={classes.body}>
            Please try again later.
          </Typography>
        ) : (
          <div className={classes.buttonWrapper}>
            <Button variant="outlined" onClick={handleRedirect}>
              Go Back To Homepage
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Error;
