import React from "react";
import { CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  loadingViewWrapper: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "center",
  },
  loadingContentWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const Loading = () => {
  const classes = useStyles();

  return (
    <div className={classes.loadingViewWrapper}>
      <div className={classes.loadingContentWrapper}>
        <CircularProgress color="secondary" size={60} />
      </div>
    </div>
  );
};

export default Loading;
