import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import {
  buildFullName,
  buildCommaSeparatedString,
} from "../../utils/component-support";

const useStyles = makeStyles((theme) => ({
  students: {
    boxSizing: "border-box",
    marginBottom: (props) => (props.isListItem ? 5 : 15),
  },
}));

const Students = ({ content, isListItem = false }) => {
  const styleProps = { isListItem };
  const classes = useStyles(styleProps);

  return (
    <Typography className={classes.students} variant="body1">
      {buildCommaSeparatedString(
        content.map((person) => buildFullName(person))
      )}
    </Typography>
  );
};

export default Students;
