import React from "react";
import { Grid, TextField } from "@material-ui/core";

const Input = ({ name, label, type, errors, value, handleChange }) => {
  return (
    <Grid item xs={12}>
      <TextField
        variant="outlined"
        name={name}
        label={label}
        type={type}
        fullWidth
        error={Boolean(errors[name])}
        helperText={errors[name]}
        value={value}
        onChange={handleChange}
      />
    </Grid>
  );
};

export default Input;
