import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import GalleryImages from "../molecules/GalleryImages";
import Person from "../molecules/Person";
import Content from "../molecules/Content";
import Heading from "../atoms/Heading";
import Resource from "../atoms/Resource";
import SubSectionHeading from "../atoms/SubSectionHeading";
import Subhead from "../atoms/Subhead";

const useStyles = makeStyles((theme) => ({
  // **These styles MUST be matching for Person, Facility & Capability components
  moleculeSubsectionWrapper: {
    paddingTop: 10,
    paddingBottom: 0,
    marginBottom: 30, // set to 0 by focusEntryContainer:last-child or moleculeSubsection:last-child
    overflow: "hidden",
    clear: "left",
    "& > div:last-child": {
      paddingBottom: 0,
    },
  },
  moleculeSubsection: {
    borderLeft: "solid .5px #eba900",
    paddingLeft: 20,
    paddingBottom: 30, // set to 0 by moleculeSubsectionWrapper:last-child
    overflow: "hidden",
    "& > div:last-child": {
      marginBottom: 0,
    },
  },
}));

const Capability = ({ content, isFocusEntry = false }) => {
  const {
    displayName,
    // type,
    description,
    expert,
    primaryImage,
    galleryImages,
  } = content;

  const classes = useStyles();

  return (
    <>
      {displayName &&
        (isFocusEntry ? (
          <Heading content={displayName} />
        ) : (
          <Subhead content={displayName} />
        ))}
      {primaryImage && (
        <Resource content={primaryImage} imageType="primaryImage" />
      )}
      {description && <Content content={description.content} />}
      {galleryImages && <GalleryImages galleryImages={galleryImages} />}
      {expert && (
        <div className={classes.moleculeSubsectionWrapper}>
          <SubSectionHeading content="Experts" />
          {expert.map((expertPerson) => (
            <div
              className={classes.moleculeSubsection}
              key={expertPerson.sys.id}
            >
              <Person content={expertPerson} />
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default Capability;
