import React, { useState, useContext } from "react";
import {
  AppBar,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Toolbar,
} from "@material-ui/core";
import HeaderMenuItemResponsive from "../atoms/HeaderMenuItemResponsive";
import HeaderNavButton from "../atoms/HeaderNavButton";
import logo from "../../assets/AMP-TAP-Logo-horz-ko.svg";
import catn2LogoWhite from "../../assets/CATN2-logo-white.png";
import { ContentContext } from "../../context/ContentContext";
import { Link as RouterLink } from "react-router-dom";
import { MoreVert, Close } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: "transparent",
    justifyContent: "center",
    padding: "10px 20px 0",
    marginTop: 10,
    marginBottom: 0,
  },
  logoLink: {
    flexGrow: 0,
  },
  logo: {
    height: 50,
    [theme.breakpoints.up("sm")]: {
      height: 60,
    },
    transition: "height 0.5s",
  },
  altLogoLink: {
    flexGrow: 1,
    marginLeft: 20,
  },
  altLogo: {
    height: 50,
    [theme.breakpoints.up("sm")]: {
      height: 60,
    },
    transition: "height 0.5s",
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionResponsive: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  popoverPaper: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      maxWidth: "unset",
      maxHeight: "unset",
      top: "0 !important",
      left: "0 !important",
    },
  },
  closeMenuItem: {
    backgroundColor: "#fff",
    borderBottom: "1.5px solid #f5f5f5",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
    justifyContent: "flex-end",
  },
  close: {
    color: "#c0c0c0",
  },
}));

// Forwards ref from MUI Link component to react-router Link
const ForwardRouterLink = React.forwardRef((props, ref) => (
  <RouterLink {...props} innerRef={ref} />
));

const HomePageHeader = ({ disciplineUrlName }) => {
  const content = useContext(ContentContext);

  const headerNavItems = content.getDisciplineNames();
  content ??
    headerNavItems.push({
      referenceName: "about",
      displayName: "About",
      urlSlug: "about",
      order: 5,
    });
  // TODO: add back in when login is functional
  // headerNavNames.login = "Login";

  const [responsiveMoreAnchorEl, setResponsiveMoreAnchorEl] = useState(null);
  const [activeRespvMenuItem, setActiveRespvMenuItem] = useState(null);
  const isResponsiveMenuOpen = Boolean(responsiveMoreAnchorEl);

  const styleProps = { disciplineUrlName };
  const classes = useStyles(styleProps);

  const handleResponsiveMenuClose = () => {
    setResponsiveMoreAnchorEl(null);
  };

  const handleResponsiveMenuOpen = (event) => {
    setResponsiveMoreAnchorEl(event.currentTarget);
  };

  const handleResponsiveSubMenuOpen = (respvMenuItem) => {
    setActiveRespvMenuItem(respvMenuItem);
  };

  const responsiveMenuId = "menu-responsive";
  const renderResponsiveMenu = (
    <Menu
      anchorEl={responsiveMoreAnchorEl}
      id={responsiveMenuId}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      keepMounted
      open={isResponsiveMenuOpen}
      onClose={handleResponsiveMenuClose}
      PaperProps={{ style: { backgroundColor: "#fff" } }}
      PopoverClasses={{ paper: classes.popoverPaper }}
    >
      <MenuItem
        onClick={handleResponsiveMenuClose}
        className={classes.closeMenuItem}
      >
        <Close htmlColor="#c0c0c0" className={classes.closeIcon} />
      </MenuItem>
      {headerNavItems.map((entry, i) => {
        return (
          <HeaderMenuItemResponsive
            key={`${entry[0]}-${i}`}
            activeDisciplineUrlName={disciplineUrlName}
            activeRespvMenuItem={activeRespvMenuItem}
            handleResponsiveSubMenuOpen={handleResponsiveSubMenuOpen}
            menuItemUrlName={entry.urlSlug}
            menuItemDisplayName={entry.displayName}
          />
        );
      })}
    </Menu>
  );

  return (
    <>
      <AppBar position="relative" elevation={0} className={classes.header}>
        <Toolbar disableGutters>
          <Link
            className={classes.logoLink}
            component={ForwardRouterLink}
            to="/"
          >
            <img src={logo} alt="AMP TAP" className={classes.logo} />
          </Link>
          <a
            href="https://www.catn2.org"
            className={classes.altLogoLink}
            target="_blank"
          >
            <img src={catn2LogoWhite} alt="CATN2" className={classes.altLogo} />
          </a>
          <div className={classes.sectionDesktop}>
            {headerNavItems.map((entry, i) => {
              return (
                <HeaderNavButton
                  key={`${entry[0]}-${i}`}
                  activeDisciplineUrlName={disciplineUrlName}
                  btnUrlName={entry.urlSlug}
                  btnDisplayName={entry.displayName}
                  isHomePage={true}
                />
              );
            })}
          </div>
          <div className={classes.sectionResponsive}>
            <IconButton
              aria-label="show more"
              aria-controls={responsiveMenuId}
              aria-haspopup="true"
              onClick={handleResponsiveMenuOpen}
            >
              <MoreVert htmlColor="#fff" />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderResponsiveMenu}
    </>
  );
};

export default HomePageHeader;
