import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  subheadCaption: {
    boxSizing: "border-box",
    fontStyle: "italic",
    marginBottom: 5,
  },
}));

const SubheadCaption = ({ content }) => {
  const classes = useStyles();
  return (
    <Typography className={classes.subheadCaption} variant="body2">
      {content}
    </Typography>
  );
};

export default SubheadCaption;
