import React, { useEffect, useState, useContext } from "react";
import HomePageCard from "../atoms/HomePageCard";
import HomePageHeroNavMsg from "../molecules/HomePageHeroNavMsg";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { ContentContext } from "../../context/ContentContext";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  heroNavWrapper: {
    width: "100%",
    height: "auto",
    backgroundImage:
      "linear-gradient(transparent 0%, transparent 30%, #fcaf25 30%, #fcaf25 100%)",
    padding: 0,
    marginTop: -300,
  },
  dispCardsWrapperWrapper: {
    float: "left",
    width: "100%",
  },
  dispCardsWrapper: {
    width: "100%",
    height: (props) => (props.isResponsiveXs ? 152 : "auto"),
    maxWidth: 1050,
    backgroundImage:
      "linear-gradient(transparent 0%, transparent 30%, #fcaf25 30%, #fcaf25 100%)",
    margin: "0 auto",
    paddingTop: (props) => (props.isResponsive ? 15 : 25),
    overflow: "hidden",
  },
  dispHeroMsgGradientBox: {
    float: "left",
    position: "relative",
    backgroundImage: "linear-gradient(#fcaf25, #e89723)",
    width: "100%",
    // The Hero Nav message box must be a fixed height so it doesn't
    // bounce up and down during animation between disciplines
    height: (props) =>
      props.isResponsiveSm
        ? 500
        : props.isResponsive
        ? 420
        : props.isResponsiveMd
        ? 260
        : 360,
  },
}));

const HomePageHeroNav = ({ handleClick }) => {
  const isResponsiveMd = useMediaQuery("(min-width:960px)");
  const isResponsive = useMediaQuery("(max-width:730px)");
  const isResponsiveSm = useMediaQuery("(max-width:480px)");
  const isResponsiveXs = useMediaQuery("(max-width:380px)");
  const styleProps = {
    isResponsive,
    isResponsiveXs,
    isResponsiveSm,
    isResponsiveMd,
  };
  const classes = useStyles(styleProps);
  const interval = 8000;

  const content = useContext(ContentContext);

  const dispUrlNamesArray = content.getDisciplineNames().map((d) => d.urlSlug);
  const [activeDispIndex, setActiveDispIndex] = useState(0);
  const [isMouseEntered, setIsMouseEntered] = useState(false);

  // Hero Nav animation
  useEffect(() => {
    let loop;
    if (!isMouseEntered) {
      loop = setInterval(() => {
        setActiveDispIndex((activeDispIndex + 1) % 4);
      }, interval);
    } else {
      clearInterval(loop);
    }
    return () => clearInterval(loop);
  }, [activeDispIndex, isMouseEntered]);

  // Hero Nav animation - user interruption resets animation loop
  const handleMouseEnter = (dispUrlName) => {
    setIsMouseEntered(true);
    setActiveDispIndex(dispUrlNamesArray.indexOf(dispUrlName));
  };

  const handleMouseLeave = () => {
    setIsMouseEntered(false);
  };

  return (
    <div className={classes.heroNavWrapper}>
      <div className={classes.dispCardsWrapperWrapper}>
        <div className={classes.dispCardsWrapper}>
          {dispUrlNamesArray.map((name, i) => {
            const imageUrl = content.getSiloPrimaryImageUrl(name);
            const statement = content.getSiloPositioningStatement(name);
            const displayImage = { name, imageUrl, statement };
            return (
              <HomePageCard
                key={name + "-" + i}
                className={classes.dispBg}
                dispUrlName={name}
                activeDispUrlName={dispUrlNamesArray[activeDispIndex]}
                dispImg={displayImage}
                cardPosition={i}
                isResponsive={isResponsive}
                isResponsiveXs={isResponsiveXs}
                handleClick={handleClick}
                handleMouseEnter={handleMouseEnter}
                handleMouseLeave={handleMouseLeave}
              />
            );
          })}
        </div>
      </div>
      <div className={classes.dispHeroMsgGradientBox}>
        {dispUrlNamesArray.map((name, i) => {
          const imageUrl = content.getSiloPrimaryImageUrl(name);
          const statement = content.getSiloPositioningStatement(name);
          const displayImage = { name, imageUrl, statement };
          return (
            <HomePageHeroNavMsg
              key={name + "-" + i}
              activeDispDisplayName={content.getDisciplineDisplayName(
                dispUrlNamesArray[activeDispIndex]
              )}
              dispDisplayName={content.getDisciplineDisplayName(name)}
              message={displayImage}
              isResponsive={isResponsive}
            />
          );
        })}
      </div>
    </div>
  );
};

export default HomePageHeroNav;
