import React, { useRef, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import StageCard from "../atoms/StageCard";
import DisciplineCollection from "./DisciplineCollection";
import { ContentContext } from "../../context/ContentContext";

const useStyles = makeStyles((theme) => ({
  stageWrapper: {
    display: "block",
    width: "100%",
    height: "100%",
    margin: "0 0 10px 0",
    [theme.breakpoints.up("md")]: {
      width: (props) => (props.isOverviewPage ? "100%" : "calc(100% + 16px)"),
      margin: (props) =>
        props.isOverviewPage ? "0 0 20px -8px" : "0 0 30px -8px",
    },
  },

  // ********************************
  // DESKTOP
  // ********************************
  header: {
    display: "flex",
    alignItems: "stretch",
    marginBottom: 2,
  },
  stageCard: {
    display: "flex",
    flex: 1,
    alignItems: "stretch",
    margin: 0,
  },
  collection: {
    display: "flex",
    alignItems: "stretch",
    flex: 1,
    margin: 0,
  },
  collNameWrapper: {
    flex: 1,
    justifyContent: "center",
    margin: 0,
  },
  collName: {
    fontSize: "1.25rem",
    fontWeight: 700,
    textAlign: "center",
  },
  body: {
    display: "flex",
    flex: 1,
    alignItems: "stretch",
    width: "100%",
  },

  // ********************************
  // RESPONSIVE
  // ********************************
  stageCardResponsive: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  bodyResponsive: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "stretch",
    width: "calc(100% + 16px)",
    margin: "0 -8px 40px -8px",
    [theme.breakpoints.up("sm")]: {
      flexWrap: "nowrap",
    },
  },
  collectionResponsive: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginTop: 10,
    paddingBottom: "0 !important",
    [theme.breakpoints.up("sm")]: {
      width: "50%",
      padding: "4px !important",
    },
  },
  collectionNameResponsive: {
    fontSize: "1.25rem",
    fontWeight: 700,
    textAlign: "center",
    padding: "0 15px",
  },
}));

const DisciplineStage = ({
  disciplineUrlName,
  stage,
  activeConcentrationId,
  isOverviewPage,
}) => {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles({ isOverviewPage });
  const content = useContext(ContentContext);
  const stageDisplayName = stage.displayName;
  const collections = content.getCollectionsForStage(
    disciplineUrlName,
    stage.urlSlug
  );
  const history = useHistory();
  // Get name of stage from previous page
  const activeFromStageDisplayName = content.getStageDisplayName(
    history.location.fromStageUrlName
  );

  // Sets a ref used for scrolling to the open concentration
  const stageWrapperRef = useRef(null);
  const paths = content.getPaths(disciplineUrlName);

  // console.log("DisciplineStage", stage);

  // Scrolls page to stageWrapper if coming from Stage component
  useEffect(() => {
    if (
      activeFromStageDisplayName === stageDisplayName &&
      stageWrapperRef.current
    ) {
      stageWrapperRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [activeFromStageDisplayName, stageDisplayName]);

  const renderStageSm = (
    <div ref={stageWrapperRef} className={classes.stageWrapper}>
      <div className={classes.stageCardResponsive}>
        <StageCard
          stageDisplayName={stageDisplayName}
          disciplineUrlName={disciplineUrlName}
          stageUrlName={stage.urlSlug}
          isOverviewPage={isOverviewPage}
        />
      </div>
      <Grid container spacing={1} className={classes.bodyResponsive}>
        {
          // Mapping by column number to produce path 'lanes' in layout
          // TODO: Test/Metrology only has single path. This means different
          // stages have to be able to express different path counts.
          // Kludgy solution, revisit and improve.
          paths &&
            paths.columnNums.map((column, i) => {
              const coll = collections
                ? collections.filter(
                    (coll) => coll.path[0].position === column
                  )[0]
                : null;
              // console.log("collection", coll);
              if (coll) {
                // console.log("renderStageSm",coll);
                const pathCount =
                  coll.displayName === "Standard" ||
                  coll.displayName === "Specialized"
                    ? 1
                    : paths.count;
                return (
                  <Grid
                    key={i}
                    container
                    item
                    xs={12}
                    sm={Math.max(12 / pathCount, 6)}
                    className={classes.collectionResponsive}
                  >
                    <Typography className={classes.collectionNameResponsive}>
                      {coll.displayName}
                    </Typography>
                    <DisciplineCollection
                      key={coll.sys.id}
                      currentCollection={coll}
                      pathCount={pathCount}
                      disciplineUrlName={disciplineUrlName}
                      stage={stage}
                      activeConcentrationId={activeConcentrationId}
                      isOverviewPage={isOverviewPage}
                    />
                  </Grid>
                );
              } else {
                return null;
              }
            })
        }
      </Grid>
    </div>
  );

  const renderStageMd = (
    <div ref={stageWrapperRef} className={classes.stageWrapper}>
      {
        // prettier-ignore
        <Grid container spacing={0} className={classes.header}>
          {/* Blank above stage card */}
          <Grid container item spacing={1} xs={12} md={3} className={classes.stageCard}>
            <Grid item xs={12}>
              <div></div>
            </Grid>
          </Grid>
          <Grid container item spacing={1} xs={12} md={9} className={classes.collection}>
          {
            // Mapping by column number to produce path 'lanes' in layout
            // TODO: Test/Metrology only has single path. This means different stages have to be able to express different path counts. Kludgy solution, revisit and improve.
            paths && paths.columnNums.map((column, i) => {
              const coll = collections ? collections.filter((coll) => coll.path[0].position === column)[0] : null;
              if (coll) {
                const pathCount = coll.displayName === "Standard" || coll.displayName === "Specialized" ? 1 : paths.count;
                return (
                  // prettier-ignore
                  <Grid key={i} container item spacing={1} xs={12} md={12 / pathCount} className={classes.collNameWrapper} >
                    <Typography className={classes.collName}>
                      {coll.displayName}
                    </Typography>
                  </Grid>
                )
              } else {
                return null;
              }
            })
          }
          </Grid>
        </Grid>
      }
      {
        <Grid container spacing={0} className={classes.body}>
          <Grid
            container
            item
            spacing={2}
            xs={12}
            md={3}
            className={classes.stageCard}
          >
            <Grid item xs={12}>
              <StageCard
                stageDisplayName={stageDisplayName}
                disciplineUrlName={disciplineUrlName}
                stageUrlName={stage.urlSlug}
                isOverviewPage={isOverviewPage}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            spacing={1}
            xs={12}
            md={9}
            className={classes.collection}
          >
            {
              // Mapping by column number to produce path 'lanes' in layout
              paths &&
                paths.columnNums.map((column, i) => {
                  const coll = collections
                    ? collections.filter(
                        (coll) => coll.path[0].position === column
                      )[0]
                    : null;
                  if (coll) {
                    const pathCount =
                      coll.displayName === "Standard" ||
                      coll.displayName === "Specialized"
                        ? 1
                        : paths.count;
                    return (
                      <DisciplineCollection
                        key={coll.sys.id}
                        currentCollection={coll}
                        pathCount={pathCount}
                        disciplineUrlName={disciplineUrlName}
                        stage={stage}
                        activeConcentrationId={activeConcentrationId}
                        isOverviewPage={isOverviewPage}
                      />
                    );
                  } else {
                    return null;
                  }
                })
            }
          </Grid>
        </Grid>
      }
    </div>
  );

  return isSm ? renderStageSm : renderStageMd;
};

export default DisciplineStage;
