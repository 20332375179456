import React from "react";

import ImageLink from "./ImageLink";
import Image from "./Image";
import ImageCaption from "./ImageCaption";
import LinkBlock from "./LinkBlock";

const Resource = ({ content, imageType }) => {
  const resourceType = content.resourceType;

  let component;

  if (
    resourceType.includes("Media Item") &&
    resourceType.includes("Hyperlink")
  ) {
    component = (
      <React.Fragment>
        <ImageLink image={content} />
        {content.displayName && <ImageCaption text={content.displayName} />}
      </React.Fragment>
    );
  } else if (resourceType.includes("Media Item")) {
    component = (
      <div style={{ float: "left" }}>
        <Image image={content} imageType={imageType} />
        {content.displayName && (
          <ImageCaption
            text={content.displayName}
            orientation={content.orientation}
          />
        )}
      </div>
    );
  } else
    component = (
      <LinkBlock content={content.url} linkDisplay={content.linkDisplay} />
    );

  return component;
};

export default Resource;
