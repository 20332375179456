import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontWeight: 700,
    fontSize: "1.45rem",
    padding: "0 0 .17rem 0",
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.6rem",
    },
  },
}));

const Heading = ({ content }) => {
  const classes = useStyles();
  return (
    <Typography className={classes.heading} variant="h5">
      {content}
    </Typography>
  );
};

export default Heading;
