import _ from "lodash"


export const buildFullName = (person) => {
  let fullName;
  if (person) {
    const { firstName, middleNameInitial, lastName, degree } = person;

    fullName =
      (firstName ? firstName : "") +
      (middleNameInitial ? " " + middleNameInitial : "") +
      (lastName ? " " + lastName : "") +
      (degree ? ", " + degree : "");
  }

  return fullName;
};

export const buildCommaSeparatedString = (arr) => {
  let string = "";

  arr.forEach((item, i) => {
    if (i === 0) {
      string += item;
    } else {
      string += ", " + item;
    }
  });

  return string.trim();
};

export const pluralizeHeading = (arr, str) => {
  return `${str}${str.endsWith("S") ? "" : arr.length === 1 ? "" : "S"}`;
};

export const slugify = (str) => {
  const a =
    "àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;";
  const b =
    "aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------";
  const p = new RegExp(a.split("").join("|"), "g");

  return (
    str &&
    str
      .toString()
      .toLowerCase()
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
      .replace(/&/g, "-and-") // Replace & with 'and'
      .replace(/[^\w-]+/g, "") // Remove all non-word characters
      .replace(/--+/g, "-") // Replace multiple - with single -
      .replace(/^-+/, "") // Trim - from start of text
      .replace(/-+$/, "")
  ); // Trim - from end of text
};

// Returns an array of objects { displayName, id } for all
// Research Thrusts for a given facultyLeads entity under a focus.
export const getAllResearchThrusts = (facultyLeads) => {
  return facultyLeads.reduce((arr1, lead) => {
    if (lead.research) {
      arr1.push(
        ...lead.research.reduce((arr2, thrust) => {
          arr2.push({
            displayName: thrust.displayName,
            id: thrust.sys.id,
          });
          return arr2;
        }, [])
      );
    }
    const finalArray = _.sortBy(arr1, "displayName");
    return finalArray;
  }, []);
};

// Transforms the "students" array from an array of
// Person entities to an array which contains a string
// of the students' full names in a format consumable
// by the Content component:
export const convertStudentsToList = (studentGroups) => {
  studentGroups.forEach((group) => {
    if (group.students) {
      const studentsArray = group.students.map((student) =>
        buildFullName(student)
      );
      const studentsString = buildCommaSeparatedString(studentsArray);
      // Must be in a shape that the Content component understands
      group.students = [{ nodeType: "text", value: studentsString, marks: [] }];
    }
  });
};
