import React, { useState, useEffect, useRef } from "react";
import { Typography } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles } from "@material-ui/core/styles";

import { useResizeObserver } from "../../hooks/useResizeObserver";
import Focus from "./Focus";
import Content from "../molecules/Content";
import GalleryImages from "../molecules/GalleryImages";
import Subhead from "../atoms/Subhead";
import Resource from "../atoms/Resource";

const useStyles = makeStyles((theme) => ({
  concentrationWrapper: {
    position: "relative",
    width: "100%",
    minHeight: 38,
    backgroundColor: "transparent",
    borderLeft: "none",
    marginTop: 0,
    marginBottom: 10,
    [theme.breakpoints.up("sm")]: {
      minHeight: (props) => (props.isOpen ? props.wrapperBaseHeight + 50 : 38),
      borderLeft: (props) =>
        props.isOpen ? `38px solid ${theme.palette.primary.main}` : "none",
    },
  },
  concentrationClicker: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: 38,
    cursor: "pointer",
    [theme.breakpoints.up("sm")]: {
      left: (props) => (props.isOpen ? -38 : 0),
      width: (props) => (props.isOpen ? 38 : "100%"),
      height: (props) => (props.isOpen ? "100%" : 38),
      minHeight: (props) => (props.isOpen ? props.wrapperBaseHeight + 50 : 38),
    },
  },
  concentrationName: {
    display: "inline-block",
    position: "relative",
    textTransform: "uppercase",
    fontWeight: 500,
    lineHeight: 1.57,
    color: "#fff",
    width: "100%",
    backgroundColor: (props) =>
      props.isOpen ? theme.palette.primary.main : "#5d86c4",
    padding: "7px 15px 6px 38px",
    transformOrigin: "initial",
    transform: "initial",
    "&::before": {
      content: "''",
      position: "absolute",
      top: (props) => (props.isOpen ? 13 : 13),
      left: (props) => (props.isOpen ? 14 : 16),
      width: 0,
      height: 0,
      marginRight: 10,
      borderTop: (props) =>
        !props.isOpen
          ? "6px solid transparent"
          : props.isDesktop
          ? "6px solid transparent"
          : "10px solid #fff",
      borderBottom: (props) =>
        !props.isOpen
          ? "6px solid transparent"
          : props.isDesktop
          ? "6px solid transparent"
          : "none",
      borderLeft: (props) =>
        !props.isOpen
          ? "10px solid #fff"
          : props.isDesktop
          ? "none"
          : "6px solid transparent",
      borderRight: (props) =>
        !props.isOpen
          ? "none"
          : props.isDesktop
          ? "10px solid #fff"
          : "6px solid transparent",
    },
    [theme.breakpoints.up("sm")]: {
      position: (props) => (props.isOpen ? "absolute" : "relative"),
      top: (props) => (props.isOpen ? 10 : 0),
      left: (props) => (props.isOpen ? 0 : 0),
      padding: (props) =>
        props.isOpen ? "7px 15px 6px 24px" : "7px 15px 6px 38px",
      transformOrigin: (props) => (props.isOpen ? "0 0" : "initial"),
      transform: (props) => (props.isOpen ? "rotate(90deg)" : "rotate(0deg)"),
      "&::before": {
        top: (props) => (props.isOpen ? 13 : 13),
        left: (props) => (props.isOpen ? 2 : 15),
      },
      width: (props) => (props.isOpen ? "auto" : "100%"),
    },
  },
}));

const Concentration = ({
  collectionId,
  concentration,
  isConcentrationOpen,
  handleChange,
}) => {
  const {
    displayName,
    focuses,
    primaryImage,
    galleryImages,
    truckRamp,
  } = concentration;

  // Sets a ref on the Concentration heading so that when it's
  // open its minimum height can be set using the heading width
  const headingRef = useRef(null);
  const nodeCurrent = useResizeObserver(headingRef);

  // Sets a ref used for scrolling to the open concentration
  const wrapperRef = useRef(null);

  const isDesktop = useMediaQuery("(min-width:600px)");

  // Set initial state
  const [isOpen, setIsOpen] = useState(false);
  const [headingWidth, setHeadingWidth] = useState(null);

  useEffect(() => {
    setIsOpen(isConcentrationOpen);
  }, [isConcentrationOpen]);

  useEffect(() => {
    if (nodeCurrent) {
      setHeadingWidth(nodeCurrent.contentRect.width);
    }
  }, [nodeCurrent]);

  // Scroll to open concentration - IE doesn't support smooth scrolling
  // Had to add a delay - for some reason first render wasn't scrolling enough
  useEffect(() => {
    if (isOpen && wrapperRef.current) {
      const scrollDelayed = () =>
        setTimeout(() => {
          wrapperRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest",
          });
        }, 200);
      scrollDelayed();
    }
  }, [isOpen]);

  // Props for styling
  const styleProps = { isDesktop };
  if (headingWidth) {
    styleProps.wrapperBaseHeight = headingWidth;
  }
  styleProps.isOpen = isOpen;
  const classes = useStyles(styleProps);

  // Methods
  const handleClick = () => {
    if (isOpen) {
      handleChange(collectionId, null);
      setIsOpen(false);
    } else {
      handleChange(collectionId, concentration.sys.id);
      setIsOpen(true);
    }
  };

  return (
    <div className={classes.concentrationWrapper} ref={wrapperRef}>
      {displayName && (
        <Typography
          className={classes.concentrationName}
          variant="body1"
          id="conentrationNameRef"
          ref={headingRef}
        >
          {displayName}
        </Typography>
      )}
      <div className={classes.concentrationClicker} onClick={handleClick}></div>
      {isOpen ? (
        <>
          {/* TODO: Discuss if Concentration should have these */}
          {primaryImage && <Resource content={primaryImage} />}
          {galleryImages && <GalleryImages galleryImages={galleryImages} />}
          {truckRamp && (
            <>
              <Subhead content="Truck Ramp"></Subhead>
              <Content content={truckRamp.content} isFocusEntry />
            </>
          )}
          {focuses &&
            focuses.map((focus) => <Focus key={focus.sys.id} focus={focus} />)}
        </>
      ) : null}
    </div>
  );
};

export default Concentration;
