import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import bulletGoldSolid from "../../assets/TAP-bullet-square-gold-solid.svg";
import bulletGraySolid from "../../assets/TAP-bullet-circle-gray-solid.svg";
import bulletGrayOutline from "../../assets/TAP-bullet-circle-gray-outline.svg";
import Content from "../molecules/Content";

const useStyles = makeStyles((theme) => ({
  orderedList: {
    boxSizing: "border-box",
    paddingLeft: 2,
    marginTop: 3,
    width: "auto",
    overflow: "hidden",
  },
  unorderedList: {
    boxSizing: "border-box",
    paddingLeft: 2,
    marginTop: 3,
    listStyleType: "none",
    width: "auto",
    overflow: "hidden",
    "& li": {
      backgroundImage: `url(${bulletGoldSolid})`,
      backgroundPosition: "0px 12px",
      backgroundSize: "6px 6px",
      backgroundRepeat: "no-repeat",
      paddingLeft: 18,
      [theme.breakpoints.up("sm")]: {
        backgroundPosition: "0px 13px",
      },
    },
    "& li li": {
      backgroundImage: `url(${bulletGraySolid})`,
    },
    "& li li li": {
      backgroundImage: `url(${bulletGrayOutline})`,
    },
  },
}));

const List = ({ content, listType }) => {
  const classes = useStyles();

  if (listType === "orderedList") {
    return (
      <ol className={classes.orderedList}>
        <Content content={content} />
      </ol>
    );
  }

  return (
    <ul className={classes.unorderedList}>
      <Content content={content} />
    </ul>
  );
};

export default List;
