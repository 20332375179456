import React from "react";
// import { Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import Content from "../molecules/Content";

const useStyles = makeStyles((theme) => ({
  code: {
    boxSizing: "border-box",
  },
}));

const Code = ({ content }) => {
  const classes = useStyles();
  return (
    <Code className={classes.code}>
      <Content content={content} />
    </Code>
  );
};

export default Code;
