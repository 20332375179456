import React from "react";
import {
  Dialog as MuiDialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";

const Dialog = ({
  title,
  content,
  ariaContent,
  isOpen,
  handleCloseDialog,
  buttonText = null,
}) => {
  return (
    <MuiDialog
      open={isOpen}
      onClose={handleCloseDialog}
      aria-labelledby={ariaContent}
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} color="primary">
          {buttonText}
        </Button>
      </DialogActions>
    </MuiDialog>
  );
};

export default Dialog;
