import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  subhead: {
    fontWeight: 700,
    fontSize: "1.15rem",
    padding: (props) =>
      props.isPerson ? ".4rem 0 .17rem 0" : ".4rem 0 .28rem 0",
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.35rem",
    },
  },
}));

const Subhead = ({ content, isPerson = false }) => {
  const styleProps = { isPerson };
  const classes = useStyles(styleProps);

  return (
    <Typography className={classes.subhead} variant="h5">
      {content}
    </Typography>
  );
};

export default Subhead;
