import React from "react";

import Content from "../molecules/Content";
import GalleryImages from "../molecules/GalleryImages";
import LinkBlock from "../atoms/LinkBlock";
import Students from "../atoms/Students";
import Resource from "../atoms/Resource";

const StudentGroup = ({ studentGroup }) => {
  const {
    // entityType,
    // type,
    researchUrl,
    students,
    primaryImage,
    galleryImages,
    description,
  } = studentGroup;

  return (
    <>
      {description && <Content content={description.content} />}
      {researchUrl && <LinkBlock content={researchUrl} />}
      {students && <Students content={students} isListItem />}
      {primaryImage && (
        <Resource content={primaryImage} imageType="primaryImage" />
      )}
      {galleryImages && <GalleryImages galleryImages={galleryImages} />}
    </>
  );
};

export default StudentGroup;
