import React, { useEffect, useState, useContext } from "react";
import Dialog from "./Dialog";
import Error from "./Error";
import HomePageContent from "../molecules/HomePageContent";
import HomePageForm from "../molecules/HomePageForm";
import HomePageHeader from "../organisms/HomePageHeader";
import HomePageHeroNav from "../organisms/HomePageHeroNav";
import Loading from "./Loading";
import ParallaxImage from "../molecules/ParallaxImage";
import headerBg from "../../assets/AMP-TAP-home-page-hdr-bg.jpg";
import heroImg2 from "../../assets/background-laser.jpg";
import { ContentContext } from "../../context/ContentContext";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.primary.main,
    backgroundImage: `url(${headerBg})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    margin: 0,
    paddingBottom: 300,
  },
  content1Wrapper: {
    position: "relative",
    display: "flex",
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center",
    padding: "70px 20px 100px",
  },
  content2Wrapper: {
    maxWidth: 600,
    margin: "100px auto",
  },
  title1: {
    color: "#fff",
    textAlign: "center",
    fontWeight: 300,
    marginBottom: 20,
  },
  title2: {
    textAlign: "center",
    fontWeight: 300,
  },
  content: {
    textAlign: "center",
  },
}));

function HomePage() {
  const history = useHistory();
  const classes = useStyles();

  const [homePage, setHomePage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [isRedirect, setIsRedirect] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const content = useContext(ContentContext);

  useEffect(() => {
    const initAsync = async () => {
      try {
        const response = await content.getHomePageAsync();
        setIsLoading(false);
        setHomePage(response);
      } catch (err) {
        setIsLoading(false);
        setIsError(true);
        setErrorMsg("There was a problem loading the page.");
        console.log("Unsuccessful ajax call msg: ", err);
      }
    };
    setIsLoading(true);
    initAsync();

    return () => {
      setIsRedirect(false);
      setIsError(false);
      setErrorMsg("");
    };
  }, [content]);

  const handleRedirect = () => {
    setIsRedirect(true);
  };

  const handleDisciplineClick = (discipline) => {
    history.push(`/discipline/${discipline}`);
  };

  let renderProblem = null;
  if (isLoading) {
    renderProblem = <Loading />;
  }
  if (isError) {
    renderProblem = (
      <Error
        isRedirect={isRedirect}
        errorMsg={errorMsg}
        handleRedirect={handleRedirect}
      />
    );
  }

  // TODO: Convert when API is wired for this
  const submitForm = (formData) => {
    // console.log("[HomePage] formData: ", formData);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const renderSignUpSuccessDialog = (
    <Dialog
      title="Thank You..."
      content="Your name and email address have been added to the SunyPoly TAP mailing list."
      ariaContent="success message for mailing address form"
      isOpen={isDialogOpen}
      handleCloseDialog={handleCloseDialog}
      buttonText="Close"
    />
  );

  // TODO: Convert form section to API-supplied values when ready
  const homePageFormHeadline = "Upgrade Your In-box";
  const homePageFormContent = [
    {
      nodeType: "paragraph",
      data: {},
      content: [
        {
          nodeType: "text",
          data: {},
          value:
            "Keep up to date with current and future events news and research. Sign up now to receive amazing information delivered straight to you!",
          marks: [],
        },
      ],
    },
  ];

  let renderHomePage = <div>Nothing</div>;
  if (homePage) {
    renderHomePage = (
      <>
        {renderSignUpSuccessDialog}
        <div className={classes.header}>
          <HomePageHeader />
          <HomePageContent
            headline={homePage.homeContent1Headline}
            content={homePage.homeContent1.content}
            isHeaderContent={true}
          />
        </div>
        <HomePageHeroNav handleClick={handleDisciplineClick} />
        <HomePageContent
          headline={homePage.homeContent2Headline}
          content={homePage.homeContent2.content}
          isHeaderContent={false}
        />
        <ParallaxImage img={heroImg2} ht={400} />
        <HomePageForm
          headline={homePageFormHeadline}
          content={homePageFormContent}
          submitForm={submitForm}
        />
      </>
    );
  }

  return renderProblem || renderHomePage;
}

export default HomePage;
